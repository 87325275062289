import React from 'react';
import { Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import styled from 'styled-components';

type Props = {
  onClick?: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  margin-top: 30px;
  margin-left: 50px;
  transition: all ease-out 0.2s;
  opacity: 0.3;
  overflow: hidden;
  box-shadow: none;

  svg {
    width: 23px;
    height: 23px;
  }

  &:hover {
    opacity: 1;
    svg {
      transition: all ease-out 0.2s;
      transform: scale(1.2);
    }
    span {
      margin-left: 0;
      opacity: 1;
    }
  }
`;

const Content = styled(Typography)`
  margin-left: -100%;
  opacity: 0;
  transition: all ease-out 0.2s;
`;

const BackButton: React.FC<Props> = ({ onClick }) => {
  if (!onClick) {
    return null;
  }

  return (
    <Container onClick={onClick}>
      <ArrowBack />
      <Content>Back</Content>
    </Container>
  );
};

export default React.memo(BackButton);
