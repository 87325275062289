import React from 'react';
import {
  BooleanField,
  Show as RaShow,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';

const Show: React.FC = (props) => (
  <RaShow {...props}>
    <TabbedShowLayout>
      <Tab label="resources.user.tabs.details">
        <TextField source="id" />
        <TextField source="phone" />
        <BooleanField source="phone_confirmed" />
        <TextField source="email" />
        <BooleanField source="email_confirmed" />
        <TextField source="name" />
        <BooleanField source="active" />
        <LocaleDateField source="created_at" showTime />
        <LocaleDateField source="updated_at" showTime />
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
