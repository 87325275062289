import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import Money from '@material-ui/icons/Money';

type Props = {
  record?: any;
};

const AddPriceAction: React.FC<Props> = ({ record }) => {
  if (!record) return null;

  return (
    <Button
      component={Link}
      // @ts-ignore
      underline="none"
      to={{
        pathname: '/prices/package/create',
        state: { package_id: record.id },
      }}
      label="ra.add_price"
    >
      <Money />
    </Button>
  );
};

export default React.memo(AddPriceAction);
