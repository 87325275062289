import React from 'react';
import {
  ImageField,
  NumberField,
  Show as RaShow,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';

const Show: React.FC = (props) => (
  <RaShow {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <LocaleDateField source="created_at" showTime />
      <LocaleDateField source="updated_at" showTime />
      <ImageField source="poster" />
      <UrlField source="link" />
      <NumberField source="skip_time" />
    </SimpleShowLayout>
  </RaShow>
);

export default Show;
