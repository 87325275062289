import { merge } from 'lodash';
import messages from 'ra-language-english';

export default merge(messages, {
  ra: {
    auth: {
      sign_up: 'Register',
      email: 'Email',
      name: 'Name',
      profile: 'Profile',
      user_menu: 'User',
      no_permission: 'Access forbidden',
    },
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      welcome: {
        title: 'Welcome to YTH Dashboard',
        description: 'Here you can manage everything!!!',
        web_button: 'Website',
      },
    },
    tabs: {
      details: 'Details',
      prices: 'Prices',
      preview: 'Preview',
    },
    copied: 'Copied!',
    meta: 'Meta',
    comments: 'Comments',
    suggests: 'Suggests',
    walls: 'Donates',
    reports: 'Reports',
    search: 'Search',
    publish: 'Publish',
    publish_trailers: 'Publish Trailers',
    add_price: 'Add Price',
  },
  resources: {
    user: {
      name: 'User |||| Users',
      tabs: {
        details: 'Details',
      },
    },
    transaction: {
      name: 'Transaction |||| Transactions',
    },
    movie: {
      name: 'Movie |||| Movies',
      fields: {
        locale: 'Locale',
        content: 'Content',
        source: 'Source',
        genres: 'Genres',
        ratings: 'Ratings',
        tags: 'Tags',
        writers: 'Writers',
        directors: 'Directors',
        stars: 'Stars',
        cast: 'Cast',
        crew: 'Crew',
      },
    },
    advertise: {
      name: 'Advertisement |||| Advertisements',
      fields: {
        locale: 'Locale',
        content: 'Content',
        movies: 'Movies',
        episodes: 'Episodes',
      },
    },
    package: {
      name: 'Subscription |||| Subscriptions',
      fields: {
        locale: 'Locale',
        content: 'Content',
        movies: 'Movies',
        serieses: 'Serieses',
      },
    },
    discount: {
      name: 'Discount |||| Discounts',
      fields: {
        locale: 'Locale',
        content: 'Content',
      },
    },
    'walls/movie': {
      name: 'Movie donate |||| Movies donates',
    },
    'walls/series': {
      name: 'Series donate |||| Serieses donates',
    },
    'prices/movie': {
      name: 'Movie price |||| Movies prices',
    },
    'prices/series': {
      name: 'Series price |||| Serieses prices',
    },
    'prices/package': {
      name: 'Package price |||| Packages prices',
    },
    'suggests/movie': {
      name: 'Movie suggest |||| Movies suggests',
      fields: {
        series_suggests: 'Series suggests',
        movie_suggests: 'Movie suggests',
      },
    },
    'suggests/series': {
      name: 'Series suggest |||| Serieses suggests',
      fields: {
        series_suggests: 'Series suggests',
        movie_suggests: 'Movie suggests',
      },
    },
    series: {
      name: 'Series |||| Serieses',
      fields: {
        locale: 'Locale',
        content: 'Content',
        source: 'Source',
        genres: 'Genres',
        ratings: 'Ratings',
        tags: 'Tags',
        writers: 'Writers',
        directors: 'Directors',
        stars: 'Stars',
        cast: 'Cast',
        crew: 'Crew',
      },
    },
    season: {
      name: 'Season |||| Seasons',
      fields: {
        locale: 'Locale',
        content: 'Content',
        source: 'Source',
        genres: 'Genres',
        ratings: 'Ratings',
        tags: 'Tags',
        writers: 'Writers',
        directors: 'Directors',
        stars: 'Stars',
        cast: 'Cast',
        crew: 'Crew',
      },
    },
    episode: {
      name: 'Episode |||| Episodes',
      fields: {
        locale: 'Locale',
        content: 'Content',
        source: 'Source',
        genres: 'Genres',
        ratings: 'Ratings',
        tags: 'Tags',
        writers: 'Writers',
        directors: 'Directors',
        stars: 'Stars',
        cast: 'Cast',
        crew: 'Crew',
      },
    },
    country: {
      name: 'Country |||| Countries',
      fields: {
        locale: 'Locale',
        content: 'Content',
      },
    },
    person: {
      name: 'Person |||| People',
      fields: {
        locale: 'Locale',
        content: 'Content',
        source: 'Source',
      },
    },
    genre: {
      name: 'Genre |||| Genres',
      fields: {
        locale: 'Locale',
        content: 'Content',
      },
    },
    rating: {
      name: 'Rating |||| Ratings',
      fields: {
        locale: 'Locale',
        content: 'Content',
      },
    },
    page: {
      name: 'Page |||| Pages',
      fields: {
        locale: 'Locale',
        content: 'Content',
      },
    },
    occupation: {
      name: 'Occupation |||| Occupations',
      fields: {
        locale: 'Locale',
        content: 'Content',
      },
    },
    tag: {
      name: 'Tag |||| Tags',
    },
    live: {
      name: 'Live |||| Lives',
      fields: {
        locale: 'Locale',
        content: 'Content',
      },
    },
    calendar: {
      name: 'Calendar Entry |||| Calendar Entries',
      fields: {
        locale: 'Locale',
        content: 'Content',
      },
    },
    role: {
      permissions: {
        'user:get_one': 'User: View',
        'user:get_list': 'User: List',
        'user:create': 'User: Create',
        'user:update': 'User: Update',
        'user:delete': 'User: Delete',

        'role:get_one': 'Role: View',
        'role:get_list': 'Role: List',
        'role:create': 'Role: Create',
        'role:update': 'Role: Update',
        'role:delete': 'Role: Delete',
      },
    },
  },
});
