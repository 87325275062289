import React from 'react';
import { Layout } from 'react-admin';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';

import useTheme from '@src/hooks/useTheme';

import AppBar from './AppBar';
import Menu from './Menu';

const CustomLayout: React.FC<any> = (props) => {
  const theme = useTheme();
  const muiTheme = React.useMemo(() => createMuiTheme(theme), [theme]);
  return (
    <ThemeProvider theme={muiTheme}>
      <Layout {...props} appBar={AppBar} menu={Menu} theme={theme} />
    </ThemeProvider>
  );
};

export default CustomLayout;
