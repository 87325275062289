const Theme = (rtl = true) => {
  const direction = rtl ? 'rtl' : 'ltr';
  document.getElementsByTagName('html')[0].setAttribute('dir', direction);
  return {
    direction,
    typography: {
      fontFamily: 'Ubuntu, sans-serif',
    },
  };
};

export default Theme;
