import React from 'react';
import {
  AutocompleteInput,
  Edit as RaEdit,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from 'react-admin';

import Currencies from '@src/assets/currencies';
import DurationInput from '@src/components/DurationInput';

import { redirect } from './utils';

const Edit: React.FC = (props) => {
  return (
    <RaEdit {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput source="series_id" reference="series" disabled>
          <SelectInput optionText="title" />
        </ReferenceInput>
        <NumberInput source="final_price" validate={[required()]} />
        <NumberInput source="show_price" validate={[required()]} />
        <AutocompleteInput
          source="currency"
          choices={Currencies}
          validate={[required()]}
          default="USD"
        />
        <NumberInput source="tax" default={0} validate={[required()]} />
        <DurationInput source="duration" />
      </SimpleForm>
    </RaEdit>
  );
};

export default Edit;
