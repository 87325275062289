import React from 'react';
import { Edit as RaEdit, required, SimpleForm, TextInput } from 'react-admin';

const Edit: React.FC = (props) => (
  <RaEdit {...props}>
    <SimpleForm redirect="show">
      <TextInput source="id" disabled />
      <TextInput source="name" validate={[required()]} />
    </SimpleForm>
  </RaEdit>
);

export default Edit;
