import { useLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import en from './en';
import fa from './fa';

const key = 'locale';
const lastLocale = localStorage.getItem(key) || 'en';

const i18nProvider = polyglotI18nProvider((locale) => {
  localStorage.setItem(key, locale);
  document.getElementsByTagName('html')[0].setAttribute('lang', locale);
  switch (locale) {
    case 'fa':
      return fa;
    case 'en':
    default:
      return en;
  }
}, lastLocale);

export const useRTL = () => {
  const locale = useLocale();
  switch (locale) {
    case 'fa':
    case 'ar':
      return true;
    default:
      return false;
  }
};

export default i18nProvider;
