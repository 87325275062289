import React from 'react';
import { Typography } from '@material-ui/core';
import padStart from 'lodash/padStart';
import styled, { css } from 'styled-components';

import { useContext } from './hooks';

const Container = styled.div`
  display: flex;
  direction: ltr;
  margin-bottom: 10px;
  justify-items: center;
`;

const slider = css`
  box-shadow: none;
  outline: none !important;
  border: none transparent;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

const Input = styled.input.attrs<{
  actualBufferEnd: number;
  max: number;
  value: number;
}>(({ value, max, actualBufferEnd, theme }) => ({
  type: 'range',
  title: '',
  style: {
    backgroundImage: `linear-gradient(93deg
    , ${theme.palette.primary.main} ${(value * 100) / max}%
    , rgba(210,210,210,1) ${(value * 100) / max}%
    , rgba(150,150,150,1) ${(actualBufferEnd * 100) / max}%
    , rgba(200,200,200,1) ${(actualBufferEnd * 100) / max}%)`,
  },
}))<{
  actualBufferEnd: number;
  max: number;
  value: number;
}>`
  outline: 0;
  width: 100%;
  margin: auto;
  appearance: none;
  height: 3px;
  transition: height 50ms ease-out;
  cursor: pointer;

  /* stylelint-disable property-no-vendor-prefix */
  &:hover {
    height: 5px;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${slider}
  }

  ::-moz-range-thumb {
    ${slider}
  }

  ::-ms-thumb {
    ${slider}
  }

  &:invalid {
    box-shadow: none;
  }

  /* stylelint-enable property-no-vendor-prefix */
`;

const Time = styled(Typography).attrs({
  variant: 'body2',
})`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  line-height: 20px;
`;

const secondsToHms = (d: number) => {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = padStart(Math.floor((d % 3600) / 60).toString(), 2, '0');
  let s = padStart(Math.floor((d % 3600) % 60).toString(), 2, '0');

  if (h) {
    return `${padStart(h.toString(), 2, '0')}:${m}:${s}`;
  }

  return `${m}:${s}`;
};

const Progress: React.FC = () => {
  const {
    showNext,
    showEpisodes,
    showQualities,
    showVolume,
    showLocales,
    setProgress,
    actualBuffer,
    duration,
    progress,
  } = useContext();
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      setProgress(parseFloat(e.target.value));
    },
    [setProgress]
  );

  if (
    showNext ||
    showEpisodes ||
    showQualities ||
    showVolume ||
    showLocales ||
    showVolume
  ) {
    return null;
  }

  return (
    <Container>
      <Input
        actualBufferEnd={actualBuffer.end}
        value={progress}
        max={duration}
        onChange={handleChange}
      />
      <Time>{secondsToHms(duration - progress)}</Time>
    </Container>
  );
};

export default React.memo(Progress);
