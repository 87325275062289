import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { Check, Settings } from '@material-ui/icons';
import styled from 'styled-components';

import Control from './Control';
import { useContext } from './hooks';

type Props = {};

export const HDs = ['اصلی', '720p', '1080p'];

const Container = styled.div`
  bottom: 20px;
  right: -20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 200px;
  min-width: 200px;
`;

const Content = styled.div`
  font-size: 14px;
  background: #222222;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  span {
    margin-right: 5px;

    &:nth-child(1) {
      font-weight: bold;
    }
  }

  svg {
    fill: ${({ theme }) => theme.palette.primary.main};
    font-size: 2em;
    margin-left: auto;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background: #333;
  }
`;

const Connector = styled.div`
  height: 20px;
  width: 100%;
`;

const HD = styled(Typography).attrs({ children: 'HD', variant: 'body2' })<{
  item?: string;
}>`
  visibility: ${({ item }) =>
    item && HDs.includes(item) ? 'visible' : 'hidden'};
  font-style: italic;
  margin: 0 5px;
`;

const Qualities: React.FC<Props> = () => {
  const {
    setShowQualities,
    showQualities,
    qualities,
    setQuality,
    quality,
  } = useContext();
  const handleMouseEnter = React.useCallback(() => setShowQualities(true), [
    setShowQualities,
  ]);
  const handleMouseLeave = React.useCallback(() => setShowQualities(false), [
    setShowQualities,
  ]);

  // TODO: uncomment after added more qualities
  // if (!qualities || qualities.length <= 1) {
  //   return null;
  // }
  if (!qualities) {
    return null;
  }
  return (
    <Control onMouseLeave={handleMouseLeave}>
      {showQualities && (
        <Container>
          <Content>
            {/*<Item*/}
            {/*  key={-1}*/}
            {/*  onClick={() => {*/}
            {/*    setShowQualities(false);*/}
            {/*    setQuality(-1);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <HD />*/}

            {/*  <span>Auto</span>*/}
            {/*  {quality === -1 && <Check />}*/}
            {/*</Item>*/}

            {qualities.map((item, index) => (
              <Item
                key={index}
                onClick={() => {
                  setShowQualities(false);
                  setQuality(index);
                }}
              >
                <HD item={item} />

                <Typography variant="body2">{item}</Typography>
                {quality === index && <Check />}
              </Item>
            ))}
          </Content>
          <Connector />
        </Container>
      )}

      <IconButton size="small" onMouseEnter={handleMouseEnter}>
        <Settings />
      </IconButton>
    </Control>
  );
};

export default React.memo(Qualities);
