import React from 'react';
import YTPlayer from 'yt-player';

import DivPlayer from '../DivPlayer';
import { useContext } from '../hooks';

import type { SimplePlayerProps } from './types';

const ytQualities: (
  | 'small'
  | 'medium'
  | 'large'
  | 'hd720'
  | 'hd1080'
  | 'highres'
  | 'default'
)[] = ['small', 'medium', 'large', 'hd720', 'hd1080', 'highres'];
const ytLabels = ['144p', '240px', '480p', '720p', '1080p', 'Highest'];

const YoutubeVideo: React.FC<SimplePlayerProps> = ({
  autoPlay,
  onEnded,
  onError,
  onTimeUpdate,
  startPosition,
  src,
}) => {
  const yt = React.useRef<YTPlayer>();

  const {
    reset,
    volume,
    muted,
    setPlaying,
    setEnd,
    videoReady,
    playing,
    progress,
    error,
    setError,
    setShowInfo,
    waitingBuffer,
    setWaitingBuffer,
    setProgress,
    setDuration,
    setVideoReady,
    quality,
    setQuality,
    setQualities,
  } = useContext();

  React.useEffect(() => {
    if (yt.current) {
      if (muted) {
        yt.current.mute();
      } else {
        yt.current.unMute();
      }
    }
  }, [muted]);

  React.useEffect(() => {
    if (yt.current) {
      yt.current.setVolume(volume);
    }
  }, [volume]);

  const handleTimeUpdate = React.useCallback(
    (seconds: number) => {
      setShowInfo(false);
      setEnd(false);
      if (playing) {
        setPlaying(true);
      }

      if (waitingBuffer) {
        setWaitingBuffer(false);
      }

      // @ts-ignore
      onTimeUpdate?.();

      setProgress(seconds);
      if (yt.current) {
        setDuration(yt.current.getDuration());
      }
    },
    [
      onTimeUpdate,
      playing,
      setDuration,
      setEnd,
      setPlaying,
      setProgress,
      setShowInfo,
      setWaitingBuffer,
      waitingBuffer,
    ]
  );

  const handleError = React.useCallback(
    (e) => {
      onError?.(e);
      setError('There is a problem playing video -ـ-');
    },
    [onError, setError]
  );

  React.useEffect(() => {
    if (yt.current && Math.abs(yt.current.getCurrentTime() - progress) > 1) {
      yt.current.seek(progress);
    }
  }, [progress]);

  React.useEffect(() => {
    if (yt.current) {
      if (playing) {
        yt.current.play();
      } else {
        yt.current.pause();
      }
    }
  }, [playing]);

  const handleEnded = React.useCallback(() => {
    setEnd(true);
    setPlaying(false);

    onEnded?.();
  }, [onEnded, setEnd, setPlaying]);

  React.useEffect(() => {
    if (yt.current) {
      if (quality !== undefined) {
        if (quality === -1) {
          yt.current.setPlaybackQuality('default');
        } else {
          yt.current.setPlaybackQuality(ytQualities[quality]);
        }
      } else {
        setQuality(-1);
      }
    }
  }, [quality, setQuality]);

  React.useEffect(() => {
    if (videoReady && yt.current) {
      yt.current.on('error', handleError);
      yt.current.on('ended', handleEnded);
      yt.current.on('timeupdate', handleTimeUpdate);
      setDuration(yt.current.getDuration());
      setQualities(ytLabels);

      return () => {
        yt.current?.removeListener('error', handleError);
        yt.current?.removeListener('ended', handleEnded);
        yt.current?.removeListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [
    handleEnded,
    handleError,
    handleTimeUpdate,
    setDuration,
    setProgress,
    setQualities,
    startPosition,
    videoReady,
  ]);

  React.useEffect(() => {
    if (src) {
      yt.current = new YTPlayer('#player-video', {
        controls: false,
        related: false,
        width: 640,
        height: 480,
      });

      if (!yt.current) return;

      yt.current.load(
        src.replace('https://www.youtube.com/watch?v=', ''),
        autoPlay
      );

      reset();
      // setProgress(startPosition);
      setPlaying(autoPlay);
      yt.current?.on('playing', () => {
        setVideoReady(true);
      });
    }

    return () => {
      yt.current?.destroy();
    };
  }, [
    autoPlay,
    reset,
    setDuration,
    setPlaying,
    setQuality,
    setVideoReady,
    src,
  ]);

  return (
    <DivPlayer
      className={`${!error ? 'opacity-100' : 'opacity-0'}`}
      id="player-video"
    />
  );
};

export default React.memo(YoutubeVideo);
