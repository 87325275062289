import React from 'react';
import { NoSsr } from '@material-ui/core';

import CloseOverlay from './CloseOverlay';
import Container from './Container';
import Controls from './Controls';
import InfoOverlay from './InfoOverlay';
import Loading from './Loading';
import Provider from './Provider';
import type { Props } from './types';
import Video from './Video';

import './Player.scss';

const Player: React.FC<Props> = ({
  title,
  subTitle,
  titleMedia,
  extraInfoMedia,

  fullPlayer = false,
  onBackPress,

  src,
  autoPlay = false,

  // onCanPlay,
  onTimeUpdate,
  onEnded,
  onError,
  onNextClick,
  onEpisodeClick,
  onCrossClick,
  startPosition,

  nexEpisode,
  episodes = [],

  screenTimeout = 5000,
}) => {
  return (
    <NoSsr>
      <Provider>
        <Container fullPlayer={fullPlayer} screenTimeout={screenTimeout}>
          <Loading />

          <InfoOverlay title={title} subtitle={subTitle ?? titleMedia} />
          <CloseOverlay
            title={title}
            subtitle={subTitle}
            onCrossClick={onCrossClick}
          />

          <Video
            src={src}
            startPosition={startPosition ?? 0}
            autoPlay={autoPlay}
            onEnded={onEnded}
            onError={onError}
            onTimeUpdate={onTimeUpdate}
          />
          <Controls
            onEpisodeClick={onEpisodeClick}
            onBackPress={onBackPress}
            episodes={episodes}
            nexEpisode={nexEpisode}
            extraInfoMedia={extraInfoMedia}
            onNextClick={onNextClick}
            titleMedia={titleMedia}
          />
        </Container>
      </Provider>
    </NoSsr>
  );
};

export default Player;
