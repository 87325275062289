import React from 'react';
import {
  BooleanField,
  ReferenceField,
  SelectField,
  Show as RaShow,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import PriceField from '@src/components/PriceField';
import ResourceReferenceField from '@src/components/ResourceReferenceField';

import { gateways } from './utils';

const Show: React.FC = (props) => (
  <RaShow {...props}>
    <SimpleShowLayout>
      <TextField source="tracking_code" />
      <ReferenceField reference="user" source="user_id">
        <TextField source="phone" />
      </ReferenceField>
      <TextField source="payer_id" />
      <TextField source="service" />
      <TextField source="type" />
      <TextField source="action" />
      <ResourceReferenceField source="resource_id" />
      <SelectField source="gateway" choices={gateways} />
      <PriceField source="first_amount" />
      <PriceField source="final_amount" />
      <BooleanField source="active" />
      <LocaleDateField source="created_at" showTime />
      <LocaleDateField source="updated_at" showTime />
    </SimpleShowLayout>
  </RaShow>
);

export default Show;
