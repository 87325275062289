import styled from 'styled-components';

const Control = styled.div`
  display: flex;
  position: relative;
  margin: auto 0;

  button {
    opacity: 0.5;
    transition: all 0.2s ease-out;

    &:hover {
      opacity: 1;
      transform: scale(1.2);
    }
  }
`;

export default Control;
