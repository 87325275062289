import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  Create as RaCreate,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

import defaultTranslations from '@src/assets/defaultTranslations';
import Locales from '@src/assets/locales';

const Create: React.FC = (props) => (
  <RaCreate {...props}>
    <SimpleForm redirect="show">
      <ArrayInput
        source="name_translations"
        label=""
        validate={[required()]}
        defaultValue={defaultTranslations}
      >
        <SimpleFormIterator>
          <AutocompleteInput
            source="locale"
            choices={Locales}
            validate={[required()]}
          />
          <TextInput
            source="content"
            multiline
            fullWidth
            validate={[required()]}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </RaCreate>
);

export default Create;
