import React from 'react';
import { FileField, FileInput, useInput, useNotify } from 'react-admin';
import Progress from '@material-ui/core/LinearProgress';
import { get } from 'lodash';

import client from '@src/data/client';

type Props = {
  accept: string;
  source: string;
  [x: string]: any;
};

const SimpleUploadFileInput: React.FC<Props> = ({ accept, ...props }) => {
  const {
    input: { name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const notify = useNotify();
  const [percent, setPercent] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const [title, setTitle] = React.useState(
    get(props, `record.${name}`, get(props, 'record.source', ''))
      .split('/')
      .pop()
  );
  const handleUploadProgress = React.useCallback((progressEvent) => {
    const newPercent = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (newPercent >= 100) {
      setPercent(-1);
    } else {
      setPercent(newPercent);
    }
  }, []);
  const handleChange = React.useCallback(
    async (file: File | null) => {
      if (file instanceof File) {
        try {
          setUploading(true);
          const data = new FormData();
          data.set('data', file);

          const response = await client.post('/admin/uploads/file', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: handleUploadProgress,
          });

          onChange(response.data.url);
          setTitle(response.data.url.split('/').pop());
          setUploading(false);
          setPercent(-1);
        } catch (e) {
          onChange(null);
          setUploading(false);
          setPercent(-1);
          setTitle('');
          notify(`${name} upload failed: ${e.message}`, 'error');
        }
      } else {
        onChange(null);
        setTitle('');
      }
    },
    [handleUploadProgress, onChange, notify, name]
  );
  const handleFormat = React.useCallback((value) => {
    return { src: value };
  }, []);
  const handleParse = React.useCallback((value) => value?.src, []);

  return (
    <div>
      <FileInput
        {...props}
        format={handleFormat}
        parse={handleParse}
        name={name}
        label={props.label}
        onChange={handleChange}
        error={!!(touched && error)}
        helperText={touched && error}
        required={isRequired}
        multiple={false}
        accept={accept}
      >
        <FileField source="src" title={title} />
      </FileInput>
      {uploading && (
        <Progress
          variant={percent === -1 ? 'indeterminate' : 'determinate'}
          value={percent}
        />
      )}
    </div>
  );
};

export default SimpleUploadFileInput;
