export const perPage = 50;
export const menuMetaKey = 'menuMeta';
export const menuCommentsKey = 'menuComments';
export const menuSuggestsKey = 'menuSuggests';
export const menuReportsKey = 'menuReports';
export const menuWallsKey = 'menuWalls';
export const themeKey = 'theme';

export const supportedSubtitles = 'text/srt,application/x-subrip,.srt';
export const supportedAudios = 'audio/aac,audio/mpeg,audio/x-wav';
export const supportedVideos = 'video/x-matroska,video/mp4,video/quicktime';
export const supportedImages = 'image/jpeg';
