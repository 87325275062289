import React from 'react';
import {
  BooleanField,
  Datagrid,
  List as RaList,
  ReferenceField,
  SelectField,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import PriceField from '@src/components/PriceField';
import ResourceReferenceField from '@src/components/ResourceReferenceField';
import { perPage } from '@src/configuration';

import exporter from './exporter';
import Filter from './Filter';
import ListActions from './ListActions';
import { gateways } from './utils';

const List: React.FC = (props) => {
  return (
    <RaList
      {...props}
      bulkActionButtons={false}
      actions={<ListActions />}
      filters={<Filter />}
      exporter={exporter}
      pagination={<Pagination />}
      perPage={perPage}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="tracking_code" />
        <ReferenceField reference="user" source="user_id" link="show">
          <TextField source="phone" />
        </ReferenceField>
        <ResourceReferenceField source="resource" />
        <TextField source="action" />
        <SelectField source="gateway" choices={gateways} />
        <PriceField source="first_amount" />
        <PriceField source="final_amount" />
        <BooleanField source="active" />
        <LocaleDateField source="created_at" showTime />
      </Datagrid>
    </RaList>
  );
};

export default List;
