import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import Episode from '@src/resources/Episode';

type Props = {
  record?: any;
};

const AddNewEpisodeButton: React.FC<Props> = ({ record }) => (
  <Box mt={2} mb={2}>
    <Button
      component={Link}
      to={{
        pathname: `/${Episode.name}/create`,
        state: { season_id: record.id },
      }}
      variant="contained"
      color="primary"
      label="Add new episode"
    >
      <Episode.icon />
    </Button>
  </Box>
);

export default React.memo(AddNewEpisodeButton);
