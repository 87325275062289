import React from 'react';
import { Show as RaShow, SimpleShowLayout, TextField } from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';

const Show: React.FC = (props) => (
  <RaShow {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <LocaleDateField source="created_at" showTime />
      <LocaleDateField source="updated_at" showTime />
    </SimpleShowLayout>
  </RaShow>
);

export default Show;
