import React from 'react';
import { Show as RaShow, SimpleShowLayout, TextField } from 'react-admin';

const Show: React.FC = (props) => (
  <RaShow {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </RaShow>
);

export default Show;
