import React from 'react';
import { NumberField, useLocale } from 'react-admin';

type Props = {
  source: string;
  addLabel?: boolean;
  record?: any;
};

const PriceField: React.FC<Props> = (props) => {
  const locale = useLocale();

  return (
    <NumberField
      {...props}
      locales={locale}
      options={{ style: 'currency', currency: props.record?.currency ?? 'USD' }}
    />
  );
};

PriceField.defaultProps = {
  addLabel: true,
};

export default PriceField;
