/* global VideoEvent */

import React from 'react';

import { useContext } from '../hooks';
import HTMLPlayer from '../HTMLPlayer';

import type { VideoProps } from './types';

const HlsVideo: React.FC<VideoProps> = ({
  autoPlay,
  onEnded,
  onError,
  onTimeUpdate,
  startPosition,
  src,
}) => {
  const video = React.useRef<HTMLVideoElement>(null);

  const {
    reset,
    volume,
    muted,
    setPlaying,
    setEnd,
    playing,
    controlBackEnd,
    setControlBackEnd,
    progress,
    error,
    setError,
    setShowInfo,
    waitingBuffer,
    setWaitingBuffer,
    setActualBuffer,
    setProgress,
    setDuration,
    setVideoReady,
    setQualities,
    quality,
    setQuality,
  } = useContext();
  const timerBuffer = React.useRef<number>();
  const [source, setSource] = React.useState<string>();

  React.useEffect(() => {
    if (typeof src === 'string') {
      setSource(src);
    } else {
      setQualities(Object.keys(src).sort());
      setQuality(0);
    }
  }, [setQualities, setQuality, src]);

  React.useEffect(() => {
    if (typeof src !== 'string' && quality !== undefined) {
      setSource(src[Object.keys(src).sort()[quality]]);
    }
  }, [quality, src]);

  React.useEffect(() => {
    if (video.current) {
      video.current.muted = muted;
    }
  }, [muted]);

  React.useEffect(() => {
    if (video.current) {
      video.current.volume = volume / 100;
    }
  }, [volume]);

  const handleEnded = React.useCallback(() => {
    if (video.current) {
      if (+startPosition === +video.current.duration && !controlBackEnd) {
        setControlBackEnd(true);
        video.current.currentTime = video.current.duration - 30;
        if (autoPlay) {
          setPlaying(true);
          video.current?.play();
        } else {
          setPlaying(false);
        }
      } else {
        setEnd(true);
        setPlaying(false);

        onEnded?.();
      }
    }
  }, [
    autoPlay,
    controlBackEnd,
    onEnded,
    setControlBackEnd,
    setEnd,
    setPlaying,
    startPosition,
  ]);

  const handleCanPlay = React.useCallback(() => {
    setVideoReady(true);
    setDuration(video.current?.duration ?? 0);
  }, [setDuration, setVideoReady]);

  const handleTimeUpdate = React.useCallback<
    React.ReactEventHandler<HTMLVideoElement>
  >(
    // @ts-ignore
    (e: VideoEvent) => {
      setShowInfo(false);
      setEnd(false);
      if (playing) {
        setPlaying(true);
      }

      if (waitingBuffer) {
        setWaitingBuffer(false);
      }

      if (timerBuffer.current) {
        clearTimeout(timerBuffer.current);
      }

      timerBuffer.current = window.setTimeout(
        () => setWaitingBuffer(true),
        1000
      );

      onTimeUpdate?.(e);

      let choseBuffer = 0;
      let lenghtBuffer = e.target.buffered.length;
      let start = 0;
      let end = 0;
      let atualTime = e.target.currentTime;

      for (let i = 1; i <= lenghtBuffer; i++) {
        let startCheck = e.target.buffered.start(i - 1);
        let endCheck = e.target.buffered.end(i - 1);

        if (endCheck > atualTime && atualTime > startCheck) {
          choseBuffer = i;

          if (endCheck > end) {
            end = endCheck;
          }

          if (startCheck < start) {
            start = startCheck;
          }
        }
      }

      setActualBuffer({
        index: choseBuffer,
        start: start,
        end: end,
      });

      setProgress(e.target.currentTime);
    },
    [
      onTimeUpdate,
      playing,
      setActualBuffer,
      setEnd,
      setPlaying,
      setProgress,
      setShowInfo,
      setWaitingBuffer,
      waitingBuffer,
    ]
  );

  const handleError = React.useCallback<
    React.ReactEventHandler<HTMLVideoElement>
  >(
    // @ts-ignore
    (e: VideoEvent) => {
      onError?.(e);
      setError('There is a problem playing video -ـ-');
    },
    [onError, setError]
  );

  React.useEffect(() => {
    if (video.current && Math.abs(video.current.currentTime - progress) > 1) {
      video.current.currentTime = progress;
    }
  }, [progress]);

  React.useEffect(() => {
    if (playing) {
      video.current?.play().catch(() => setPlaying(false));
    } else {
      video.current?.pause();
    }
  }, [playing, setPlaying]);

  React.useEffect(() => {
    reset();
    setProgress(startPosition);
  }, [reset, setProgress, startPosition]);

  return (
    <HTMLPlayer
      playsInline
      muted
      src={source}
      className={`${!error ? 'opacity-100' : 'opacity-0'}`}
      id="player-video"
      ref={video}
      controls={false}
      autoPlay={autoPlay}
      onCanPlay={handleCanPlay}
      onTimeUpdate={handleTimeUpdate}
      onError={handleError}
      onEnded={handleEnded}
    />
  );
};

export default React.memo(HlsVideo);
