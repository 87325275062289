import React from 'react';
import { Close } from '@material-ui/icons';
import styled from 'styled-components';

import { useContext } from './hooks';
import { HDs } from './Qualities';

type Props = {
  title: string;
  subtitle?: string;
  onCrossClick?: () => void;
};

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  transition: all 0.5s ease-out;
  z-index: 2;
  display: flex;
  flex-direction: column;

  .error {
    text-align: center;
    color: #ddd;
    margin: auto;
    transition: all 0.2s ease;

    .links-error {
      display: inline-flex;
      margin: auto;

      div {
        display: flex;
        align-items: center;
        margin: 0 5px;
        background: #333;
        padding: 10px;
        font-weight: bold;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.2s ease;

        &:hover {
          background: ${({ theme }) => theme.palette.primary.main};
        }
      }
    }

    h1 {
      font-size: 2em;
    }

    p {
      font-size: 1.5em;
      margin: 20px;
    }
  }

  .header-loading {
    display: flex;
    color: #ffffff;
    align-items: center;

    .title {
      font-size: 1.5em;
      font-weight: bold;
    }

    .sub-title {
      font-size: 1.1em;
    }

    svg {
      opacity: 0.5;
      margin-left: auto;
      font-size: 4em;
      padding: 10px;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.2);
        opacity: 1;
      }
    }
  }
`;

const CloseOverlay: React.FC<Props> = ({ title, subtitle, onCrossClick }) => {
  const { error, videoReady, qualities, quality, setQuality } = useContext();

  return (
    <Container
      className={
        !videoReady || (videoReady && error)
          ? 'opacity-100'
          : 'opacity-0 z-index-0'
      }
    >
      {(title || subtitle) && (
        <div className="header-loading">
          <div>
            <div className="title">{title}</div>
            <div className="sub-title">{subtitle}</div>
          </div>
          {onCrossClick && <Close onClick={onCrossClick} />}
        </div>
      )}

      <div className={`error ${error ? 'opacity-100' : 'opacity-0'}`}>
        {error && (
          <div>
            <h1>{error}</h1>
            {qualities && qualities.length > 1 && (
              <div>
                <p>Try accessing another quality</p>
                <div className="links-error">
                  {qualities.map((item, index) => (
                    <div key={index} onClick={() => setQuality(index)}>
                      {HDs.includes(item) && <span>HD</span>}

                      <span>{item}</span>
                      {quality === index && <Close />}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

export default React.memo(CloseOverlay);
