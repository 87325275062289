import React from 'react';
import {
  AutocompleteArrayInput,
  Create as RaCreate,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const Create: React.FC = (props) => (
  <RaCreate {...props}>
    <SimpleForm redirect="show">
      <TextInput source="suggest_type" />
      <ReferenceInput
        source="movie_id"
        reference="movie"
        validate={[required()]}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceArrayInput source="movie_suggests" reference="movie" fullWidth>
        <AutocompleteArrayInput optionText="title" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="series_suggests"
        reference="series"
        fullWidth
      >
        <AutocompleteArrayInput optionText="title" />
      </ReferenceArrayInput>
    </SimpleForm>
  </RaCreate>
);

export default Create;
