import Icon from '@material-ui/icons/Movie';

import List from './List';
// import Show from './Show';

export default {
  name: 'comments/movie',
  icon: Icon,
  list: List,
  // show: Show,
};
