import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';

import Season from '@src/resources/Season';

type Props = {
  record?: any;
};

const AddNewSeasonAction: React.FC<Props> = ({ record }) => (
  <Button
    component={Link}
    // @ts-ignore
    underline="none"
    to={{
      pathname: `/${Season.name}/create`,
      state: { series_id: record.id },
    }}
    label="Add new season"
  >
    <Season.icon />
  </Button>
);

export default React.memo(AddNewSeasonAction);
