import styled from 'styled-components';

const HTMLPlayer = styled.video`
  height: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  cursor: none;
`;

export default HTMLPlayer;
