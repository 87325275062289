import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
} from 'react-admin';
import { chunk } from 'lodash';
import pluralize from 'pluralize';
import { stringify } from 'query-string';

import client from './client';

/**
 * Query a data provider and return a promise for a response
 *
 * @example
 * dataProvider(GET_ONE, 'posts', { id: 123 })
 *  => Promise.resolve({ data: { id: 123, title: "hello, world" } })
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the action type
 * @returns {Promise} the Promise for a response
 */
// @ts-ignore
const dataProvider = async (type, resource, payload) => {
  try {
    let baseURL: string;
    switch (resource) {
      case 'authentication':
        baseURL = `/${resource}`;
        break;
      case 'series':
        baseURL = `/admin/serieses`;
        break;
      case 'comments/series':
        baseURL = `/admin/comments/serieses`;
        break;
      case 'suggests/series':
        baseURL = `/admin/suggests/serieses`;
        break;
      case 'reports/series':
        baseURL = `/admin/reports/serieses`;
        break;
      case 'prices/series':
        baseURL = `/admin/prices/serieses`;
        break;
      case 'walls/series':
        baseURL = `/admin/walls/serieses`;
        break;
      default:
        baseURL = `/admin/${pluralize(resource)}`;
    }
    switch (type) {
      case GET_LIST: {
        const {
          pagination: { page, perPage },
          sort: { field, order },
          filter,
        } = payload;
        if (perPage >= 1000) {
          const newPerPage = 1000;
          // its export so lets try another way
          const first_response = await client.get(
            `${baseURL}?${stringify({
              page,
              per_page: newPerPage,
              order,
              sort: field,
              ...filter,
            })}`
          );
          const total = first_response.data.total;
          if (total < newPerPage) {
            return {
              data: first_response.data.items,
              total,
            };
          } else {
            const pages = Math.ceil(total / newPerPage);
            let items = [...first_response.data.items];
            for (let i = 2; i <= pages; i++) {
              const response = await client.get(
                `${baseURL}?${stringify({
                  page: i,
                  per_page: newPerPage,
                  order,
                  sort: field,
                  ...filter,
                })}`
              );
              items.push(...response.data.items);
            }
            return { data: items, total };
          }
        } else {
          const response = await client.get(
            `${baseURL}?${stringify({
              page,
              per_page: perPage,
              order,
              sort: field,
              ...filter,
            })}`
          );
          return { data: response.data.items, total: response.data.total };
        }
      }
      case GET_MANY_REFERENCE: {
        const {
          pagination: { page, perPage },
          sort: { field, order },
          filter,
          target,
          id,
        } = payload;
        const response = await client.get(
          `${baseURL}?${stringify({
            page,
            per_page: perPage,
            order,
            sort: field,
            [target]: id,
            ...filter,
          })}`
        );
        return { data: response.data.items, total: response.data.total };
      }
      case GET_MANY: {
        const { ids } = payload as { ids?: string[] };
        const responses = await Promise.all(
          chunk(ids, 10).map(
            async (c) =>
              await client.get(
                `${baseURL}?${stringify({
                  ids: c,
                })}`
              )
          )
        );

        const items = responses.map((r) => r.data.items).flat();
        return { data: items, total: items.length };
      }
      case GET_ONE: {
        const { id } = payload;
        const response = await client.get(`${baseURL}/${id}`);
        return { data: response.data };
      }
      case UPDATE: {
        const { id, data } = payload;
        const response = await client.put(`${baseURL}/${id}`, data);
        return { data: response.data };
      }
      case CREATE: {
        const { data } = payload;
        const response = await client.post(`${baseURL}`, data);
        return { data: response.data };
      }
      case DELETE: {
        const { id } = payload;
        await client.delete(`${baseURL}/${id}`);
        return { data: id };
      }
      case DELETE_MANY: {
        const { ids = [] } = payload;
        // TODO show exactly which ids deleted
        await Promise.all(
          // @ts-ignore
          ids.map((id) => dataProvider(DELETE, resource, { id }))
        );
        return { data: ids };
      }
      default:
        return new Error(`Unsupported Data Provider request type ${type}`);
    }
  } catch (e) {
    return await Promise.reject(e);
  }
};

export default dataProvider;
