import React from 'react';
import { Button } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Publish from '@material-ui/icons/Publish';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import authProvider from '@src/authentication';
import client from '@src/data/client';
import useInterval from '@src/hooks/useInterval';

type Props = {
  record?: any;
};

const StatusAction: React.FC<Props> = ({ record }) => {
  const taskIdAtom = React.useMemo(
    () =>
      record?.id ? atomWithStorage<string>(`task-${record?.id}`, '') : atom(''),
    [record]
  );
  const [taskId, setTaskId] = useAtom(taskIdAtom);
  const [status, setStatus] = React.useState<string>();
  const handlePublish = React.useCallback(async () => {
    if (record) {
      try {
        const { data } = await client.put<{ task: string }>(
          `/admin/movies/${record.id}/generate`
        );
        setTaskId(data.task);
      } catch (e) {
        await authProvider.checkError(e);
      }
    }
  }, [record, setTaskId]);

  const handlePublishTrailers = React.useCallback(async () => {
    if (record) {
      try {
        await client.put<{ tasks: string[] }>(
          `/admin/movies/${record.id}/generate_trailers`
        );
      } catch (e) {
        await authProvider.checkError(e);
      }
    }
  }, [record]);

  const checkStatus = React.useCallback(async () => {
    try {
      if (!taskId) {
        return;
      }
      const { data } = await client.get<{ state: string }>(
        `/admin/status/${taskId}`
      );
      setStatus(data.state);
      console.log(data.state);
      if (data.state === 'SUCCESS' || data.state === 'REVOKED') {
        setTaskId('');
      }
    } catch (e) {
      await authProvider.checkError(e);
    }
  }, [setTaskId, taskId]);

  React.useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  useInterval(checkStatus, taskId ? 10000 : null);
  const loading = status === 'PENDING';

  return (
    <>
      <Button label="ra.publish" onClick={handlePublish} disabled={loading}>
        {loading ? <CircularProgress size={16} /> : <Publish />}
      </Button>
      <Button
        label="ra.publish_trailers"
        onClick={handlePublishTrailers}
        disabled={loading}
      >
        <Publish />
      </Button>
    </>
  );
};

export default React.memo(StatusAction);
