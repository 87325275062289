import { Reducer } from 'redux';

import { themeKey } from '@src/configuration';

import { Actions, CHANGE_THEME, State } from './types';

const initialState: State = {
  name: localStorage.getItem(themeKey) ?? 'light',
};

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...state,
        name: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
