import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { VideoLibrary } from '@material-ui/icons';
import styled from 'styled-components';

import Control from './Control';
import { useContext } from './hooks';
import type { Episode } from './types';

type Props = {
  onChange?: (id: string, playing: boolean) => void;
  episodes?: Episode[];
};

const Percent = styled.div<{ value?: number }>`
  height: 3px;
  width: ${({ value = 0 }) => Math.min(value, 100)}px;
  background: ${({ theme }) => theme.palette.primary.main};
  margin-left: auto;
`;

const NextEpisode: React.FC<Props> = ({ onChange, episodes }) => {
  const { setShowEpisodes, showEpisodes } = useContext();
  const episodesScroll = React.useRef<HTMLDivElement>(null);
  const scrollToSelected = React.useCallback(() => {
    let element = episodesScroll.current;
    if (element) {
      const selected = element.getElementsByClassName('selected')[0];
      // @ts-ignore
      const position = selected.offsetTop;
      // @ts-ignore
      const height = selected.offsetHeight;
      element.scrollTop = position - height * 2;
    }
  }, []);

  const handleMouseEnter = React.useCallback(() => setShowEpisodes(true), [
    setShowEpisodes,
  ]);
  const handleMouseLeave = React.useCallback(() => setShowEpisodes(false), [
    setShowEpisodes,
  ]);

  React.useEffect(() => {
    if (showEpisodes) {
      scrollToSelected();
    }
  }, [scrollToSelected, showEpisodes]);

  if (!onChange) {
    return null;
  }

  return (
    <Control onMouseLeave={handleMouseLeave}>
      {showEpisodes && (
        <div className="item-component item-component-list-rep">
          <div className="content-list-reprodution">
            <div className="title">Episodes</div>
            <div
              ref={episodesScroll}
              className="list-list-reproduction scroll-clean-player"
            >
              {episodes?.map((item, index) => (
                <div
                  key={item.id}
                  className={`item-list-reproduction ${
                    item.playing && 'selected'
                  }`}
                  onClick={() => onChange?.(item.id, item.playing ?? false)}
                >
                  <div>
                    <Typography>{index + 1}</Typography>
                    <Typography variant="body1" noWrap>
                      {item.title}
                    </Typography>
                  </div>

                  <Percent value={item.percent} />
                </div>
              ))}
            </div>
          </div>
          <div className="box-connector" />
        </div>
      )}
      {episodes && episodes.length > 1 && (
        <IconButton size="small" onMouseEnter={handleMouseEnter}>
          <VideoLibrary />
        </IconButton>
      )}
    </Control>
  );
};

export default React.memo(NextEpisode);
