import React from 'react';
import { IconButton } from '@material-ui/core';
import { Replay10 } from '@material-ui/icons';

import Control from './Control';
import { useContext } from './hooks';

const seconds = 10;

const Backward10Control: React.FC = () => {
  const { progress, setProgress } = useContext();

  const handleClick = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (e) => {
      e.stopPropagation();

      if (progress - seconds <= 0) {
        setProgress(0);
        return;
      }

      setProgress(progress - seconds);
    },
    [progress, setProgress]
  );

  return (
    <Control>
      <IconButton size="small" onClick={handleClick}>
        <Replay10 />
      </IconButton>
    </Control>
  );
};

export default React.memo(Backward10Control);
