import axios from 'axios';

import * as jwtManager from '@src/authentication/jwtManager';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
});

client.interceptors.request.use(async (config) => {
  const token = jwtManager.getAccessToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  } else {
    await jwtManager.refreshAccessToken();
    config.headers['Authorization'] = `Bearer ${jwtManager.getAccessToken()}`;
  }
  return config;
});

export default client;
