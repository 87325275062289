import { AxiosError } from 'axios';
import gravatar from 'gravatar';
import { AuthProvider } from 'ra-core';

import client from '@src/data/client';
import simpleClient from '@src/data/simpleClient';

import * as jwtManager from './jwtManager';
import { Login } from './types';

const authProvider: AuthProvider = {
  login: async ({ username, password }: Login) => {
    try {
      const {
        data: { access_token, refresh_token, permissions, access_expires },
      } = await simpleClient.post('/admin/authentication/login', {
        username,
        password,
      });
      jwtManager.setAccessToken(access_token, access_expires);
      jwtManager.setPermissions(permissions);
      jwtManager.setRefreshToken(refresh_token);
      return new Promise((resolve) => setTimeout(resolve, 1500));
    } catch (e) {
      throw new Error('ra.auth.sign_in_error');
    }
  },
  logout: () => {
    jwtManager.erase();
    return Promise.resolve();
  },
  checkError: async (e: AxiosError) => {
    switch (e?.response?.status) {
      case 403:
        throw new Error('ra.auth.no_permission');
      case 401:
        return await jwtManager.refreshAccessToken();
      default:
        return Promise.resolve();
    }
  },
  getIdentity: async () => {
    try {
      const {
        data: { id, name, email },
      } = await client.get('/authentication/me');
      return Promise.resolve({
        id,
        fullName: name,
        avatar: gravatar.url(email),
      });
    } catch (e) {
      throw new Error('ra.auth.auth_check_error');
    }
  },
  checkAuth: async () => {
    if (!jwtManager.getAccessToken()) {
      await jwtManager.refreshAccessToken();
    }
    return jwtManager.getAccessToken() ? Promise.resolve() : Promise.reject();
  },
  getPermissions: async () => {
    await jwtManager.waitForRefreshAccessToken();
    const permissions = jwtManager.getPermissions();
    if (permissions) {
      return Promise.resolve(permissions);
    }
    return Promise.reject();
  },
};

export default authProvider;
