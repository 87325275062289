import React from 'react';
import styled, { css } from 'styled-components';

import { useContext } from './hooks';

type Props = {
  fullPlayer: boolean;
  screenTimeout: number;
  children?: React.ReactNode;
};

const Container: React.FC<Props> = ({
  children,
  fullPlayer,
  screenTimeout,
}) => {
  const playerElement = React.useRef<HTMLDivElement>(null);
  const timerRef = React.useRef<number>();

  const {
    fullScreen,
    setFullScreen,
    setShowInfo,
    playing,
    // setPlaying,
    setShowControls,
  } = useContext();

  const controlScreenTimeOut = React.useCallback(() => {
    setShowControls(false);
    if (!playing) {
      setShowInfo(true);
    }
  }, [playing, setShowControls, setShowInfo]);

  const hoverScreen = React.useCallback(() => {
    setShowControls(true);
    setShowInfo(false);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(controlScreenTimeOut, screenTimeout);
  }, [controlScreenTimeOut, screenTimeout, setShowControls, setShowInfo]);

  const handleDoubleClick = React.useCallback(() => {
    setFullScreen(!fullScreen);
  }, [fullScreen, setFullScreen]);

  const setStateFullScreen = React.useCallback(() => {
    if (document.fullscreenElement) {
      setFullScreen(true);
    } else {
      setFullScreen(false);
    }
  }, [setFullScreen]);

  const getKeyBoardInteraction = React.useCallback(
    (e) => {
      switch (e.keyCode) {
        // space
        // case 32:
        //   if (!playing) {
        //     setPlaying(true);
        //     hoverScreen();
        //   } else {
        //     setPlaying(false);
        //     hoverScreen();
        //   }
        //   break;
        case 122:
          setFullScreen(!fullScreen);
          break;
      }
    },
    // [fullScreen, hoverScreen, playing, setFullScreen, setPlaying]
    [fullScreen, setFullScreen]
  );

  React.useEffect(() => {
    document.addEventListener('keydown', getKeyBoardInteraction, false);
    playerElement.current?.addEventListener(
      'fullscreenchange',
      setStateFullScreen
    );
    window.addEventListener('resize', setStateFullScreen);
    return () => {
      window.removeEventListener('resize', setStateFullScreen);
      document.removeEventListener('keydown', getKeyBoardInteraction, false);
    };
  }, [getKeyBoardInteraction, setStateFullScreen]);

  React.useEffect(() => {
    if (fullScreen) {
      playerElement.current?.requestFullscreen();
    } else {
      if (document.fullscreenElement) {
        document.exitFullscreen().catch(console.log);
      }
    }
  }, [fullScreen]);

  // const handleTogglePlay = React.useCallback(() => setPlaying(!playing), [
  //   playing,
  //   setPlaying,
  // ]);

  return (
    <Base
      id="player-component"
      onMouseMove={hoverScreen}
      ref={playerElement}
      $full={fullPlayer}
      // onClick={handleTogglePlay}
      onDoubleClick={handleDoubleClick}
    >
      {children}
    </Base>
  );
};

export default React.memo(Container);

const Base = styled.div.attrs({ className: 'player-component' })<{
  $full: boolean;
}>`
  *,
  :hover,
  :focus {
    user-select: none !important;
    box-shadow: none !important;

    input,
    textarea {
      user-select: text !important;
    }
  }

  width: 100%;
  height: 100%;
  min-height: 240px;
  position: relative;
  background: #000;
  overflow: hidden;

  ${({ $full }) =>
    $full &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10000;
    `}

  .MuiIconButton-root, .MuiTypography-colorTextPrimary, .MuiSvgIcon-root {
    color: white;
  }
`;
