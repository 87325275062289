import React from 'react';
import {
  ImageField,
  Show as RaShow,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';

import ShowActions from './ShowActions';

const Show: React.FC = (props) => (
  <RaShow {...props} actions={<ShowActions />}>
    <SimpleShowLayout>
      <ImageField source="cover.source" />
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="description" />
      <LocaleDateField source="release_date" showTime />
      <LocaleDateField source="created_at" showTime />
      <LocaleDateField source="updated_at" showTime />
      <ImageField source="poster.source" />
    </SimpleShowLayout>
  </RaShow>
);

export default Show;
