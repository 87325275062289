import React from 'react';
import {
  BooleanInput,
  DateTimeInput,
  Filter as RaFilter,
  SelectInput,
} from 'react-admin';

import { gateways } from './utils';

const Filter: React.FC = (props) => (
  <RaFilter {...props}>
    <DateTimeInput source="from_date" />
    <DateTimeInput source="to_date" />
    <BooleanInput source="active" />
    <SelectInput source="gateway" choices={gateways} />
  </RaFilter>
);

export default Filter;
