import React from 'react';
import { Filter as RaFilter, TextInput } from 'react-admin';

const Filter: React.FC = (props) => (
  <RaFilter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </RaFilter>
);

export default Filter;
