import React from 'react';
import {
  Create as RaCreate,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

const Create: React.FC = (props) => (
  <RaCreate {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={[required()]} />
    </SimpleForm>
  </RaCreate>
);

export default Create;
