import styled from 'styled-components';

const DivPlayer = styled.div`
  min-height: 100%;
  max-height: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  cursor: none;
`;

export default DivPlayer;
