import simpleClient from '@src/data/simpleClient';

export const REFRESH_KEY = 'refresh_token';
export const ACCESS_KEY = 'access_token';
export const PERMISSIONS_KEY = 'permissions';

let inMemoryAccessToken: string | null = null;
let inMemoryPermissions: object | null = null;
let inMemoryDelay: number;
let refreshTimeOutId: number;
let isRefreshing: Promise<void> | null = null;

window.addEventListener('storage', (event) => {
  switch (event.key) {
    case ACCESS_KEY:
      inMemoryAccessToken = event.newValue;
      if (event.newValue === null || event.oldValue === null) {
        window.location.reload();
      }
      break;
    case PERMISSIONS_KEY:
      if (event.newValue) {
        inMemoryPermissions = JSON.parse(event.newValue);
      } else {
        inMemoryPermissions = null;
      }
  }
});

const abortRefreshAccessTokenTimeout = () => {
  if (refreshTimeOutId) {
    window.clearTimeout(refreshTimeOutId);
  }
};

export const erase = () => {
  inMemoryAccessToken = null;
  abortRefreshAccessTokenTimeout();
  localStorage.removeItem(ACCESS_KEY);
  localStorage.removeItem(REFRESH_KEY);
  localStorage.removeItem(PERMISSIONS_KEY);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_KEY);
};

export const setRefreshToken = (token: string) => {
  return localStorage.setItem(REFRESH_KEY, token);
};

export const getAccessToken = () => {
  return inMemoryAccessToken;
};

export const refreshAccessTokenTimeout = (delay: number) => {
  abortRefreshAccessTokenTimeout();
  inMemoryDelay = delay - 5000;
  refreshTimeOutId = window.setTimeout(refreshAccessToken, inMemoryDelay); // Validity period of the token in seconds, minus 5 seconds
};

export const setAccessToken = (token: string, delay: number) => {
  localStorage.setItem(ACCESS_KEY, token);
  inMemoryAccessToken = token;
  refreshAccessTokenTimeout(delay);
};

export const getPermissions = () => {
  return inMemoryPermissions;
};

export const setPermissions = (permissions: object) => {
  inMemoryPermissions = permissions;
  localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
};

export const refreshAccessToken = async () => {
  isRefreshing = (async () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      throw new Error('ra.auth.auth_check_error');
    }

    try {
      const {
        data: { access_token, permissions, access_expires },
      } = await simpleClient.post(
        '/admin/authentication/refresh',
        {},
        {
          headers: { Authorization: `Bearer ${refreshToken}` },
        }
      );

      setAccessToken(access_token, access_expires);
      setPermissions(permissions);
      return Promise.resolve();
    } catch (e) {
      erase();
      throw new Error('ra.auth.auth_check_error');
    }
  })();

  return isRefreshing;
};

export const waitForRefreshAccessToken = () => {
  if (!isRefreshing) {
    return Promise.resolve();
  }
  return isRefreshing.then(() => {
    isRefreshing = null;
    return true;
  });
};
