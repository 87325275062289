import React from 'react';
import { IconButton } from '@material-ui/core';
import { Pause, PlayArrow } from '@material-ui/icons';

import Control from './Control';
import { useContext } from './hooks';

const PlayControl: React.FC = () => {
  const { playing, setPlaying } = useContext();

  const handleClick = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (e) => {
      e.stopPropagation();

      setPlaying(!playing);
    },
    [playing, setPlaying]
  );

  return (
    <Control>
      {!playing && (
        <IconButton size="small" onClick={handleClick}>
          <PlayArrow />
        </IconButton>
      )}
      {playing && (
        <IconButton size="small" onClick={handleClick}>
          <Pause />
        </IconButton>
      )}
    </Control>
  );
};

export default React.memo(PlayControl);
