import React from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  DateTimeInput,
  Edit as RaEdit,
  FormTab,
  ReferenceArrayInput,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';

import defaultTranslations from '@src/assets/defaultTranslations';
import Locales from '@src/assets/locales';
import SimpleUploadFileInput from '@src/components/SimpleUploadFileInput';
import UploadImageInput from '@src/components/UploadImageInput';
import UploadS3FileInput from '@src/components/UploadS3FileInput';
import {
  supportedAudios,
  supportedSubtitles,
  supportedVideos,
} from '@src/configuration';

const Edit: React.FC = (props) => (
  <RaEdit {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Title">
        <ArrayInput
          source="title_translations"
          label=""
          validate={[required()]}
          defaultValue={defaultTranslations}
        >
          <SimpleFormIterator>
            <AutocompleteInput
              source="locale"
              choices={Locales}
              validate={[required()]}
            />
            <TextInput source="content" fullWidth validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Description" path="description">
        <ArrayInput
          source="description_translations"
          label=""
          validate={[required()]}
          defaultValue={defaultTranslations}
        >
          <SimpleFormIterator>
            <AutocompleteInput
              source="locale"
              choices={Locales}
              validate={[required()]}
            />
            <TextInput
              source="content"
              multiline
              fullWidth
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Details" path="details">
        <ReferenceArrayInput source="genres" reference="genre">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="ratings" reference="rating">
          <AutocompleteArrayInput optionText="title" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="tags" reference="tag">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <DateTimeInput source="release_date" validate={[required()]} />
        <UploadImageInput source="poster.source" validate={[required()]} />
        <UploadImageInput source="cover.source" validate={[required()]} />
        <ArrayInput source="trailers" defaultValue={[]}>
          <SimpleFormIterator>
            <UploadS3FileInput
              accept={supportedVideos}
              source="source"
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Cast" path="cast">
        <ReferenceArrayInput source="directors" reference="person">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="writers" reference="person">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="stars" reference="person">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="cast" reference="person">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="crew" reference="person">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </FormTab>

      <FormTab label="Stream" path="stream">
        <UploadS3FileInput
          accept={supportedVideos}
          source="video.source"
          validate={[required()]}
        />
        <ArrayInput source="audios" defaultValue={[]}>
          <SimpleFormIterator>
            <AutocompleteInput
              source="locale"
              choices={Locales}
              validate={[required()]}
            />
            <UploadS3FileInput
              accept={supportedAudios}
              source="source"
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="subtitles" defaultValue={[]}>
          <SimpleFormIterator>
            <AutocompleteInput
              source="locale"
              choices={Locales}
              validate={[required()]}
            />
            <SimpleUploadFileInput
              accept={supportedSubtitles}
              source="source"
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </RaEdit>
);

export default Edit;
