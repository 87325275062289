import React from 'react';
import { FC } from 'react';
import { useAuthenticated, useTranslate } from 'react-admin';
import DollarIcon from '@material-ui/icons/AttachMoney';

import client from '@src/data/client';

import CardWithIcon from './CardWithIcon';

interface Props {}

const MonthlyRevenue: FC<Props> = () => {
  useAuthenticated();
  const translate = useTranslate();
  const [data, setData] = React.useState('-');
  React.useEffect(() => {
    (async () => {
      try {
        const response = await client.get(`/admin/walls/main`);
        setData(response.data.amount.toString());
      } catch (e) {
        // ignore error
      }
    })();
  }, []);

  return (
    <CardWithIcon
      to="/walls/movie"
      icon={DollarIcon}
      title={translate('ra.walls')}
      subtitle={data}
    />
  );
};

export default MonthlyRevenue;
