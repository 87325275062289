import merge from 'lodash/merge';

import baseTheme from './base';
const main = '#00b140';

const Theme = (rtl = true) => {
  return merge(baseTheme(rtl), {
    palette: {
      type: 'dark',
      primary: {
        main,
      },
      secondary: {
        light: '#58e46f',
        main: 'rgba(0, 177, 64, 0.8)',
        dark: '#00800d',
        contrastText: '#fff',
      },
      background: {
        default: '#101010',
      },
    },

    shape: {
      borderRadius: 10,
    },
    overrides: {
      RaMenuItemLink: {
        root: {
          borderLeft: '3px solid #101010',
        },
        active: {
          borderLeft: `3px solid ${main}`,
        },
      },
      MuiPaper: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '1px solid #1f1f1c',
          backgroundClip: 'padding-box',
        },
      },
      MuiButton: {
        contained: {
          backgroundColor: '#424242',
          color: main,
          boxShadow: 'none',
        },
      },
      MuiAppBar: {
        colorSecondary: {
          color: '#fff',
          backgroundColor: '#333333',
          border: '1px solid #1f1f1c',
        },
      },
      MuiLinearProgress: {
        colorPrimary: {
          backgroundColor: '#555555',
        },
        barColorPrimary: {
          backgroundColor: '#777777',
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.04)',
          '&$disabled': {
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
          },
        },
      },
    },
  });
};

export default Theme;
