import React from 'react';

import HlsVideo from './HlsVideo';
import HTMLVideo from './HTMLVideo';
import type { VideoProps } from './types';
import YoutubeVideo from './YoutubeVideo';

const Video: React.FC<VideoProps> = ({ src, ...rest }) => {
  if (typeof src === 'string') {
    if (src.endsWith('.m3u8')) {
      return <HlsVideo src={src} {...rest} />;
    } else if (src.startsWith('https://www.youtube.com/')) {
      return <YoutubeVideo src={src} {...rest} />;
    }

    return <HTMLVideo src={src} {...rest} />;
  } else {
    return <HTMLVideo src={src} {...rest} />;
  }
};

export default React.memo(Video);
