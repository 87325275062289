import React from 'react';
import {
  ImageField,
  Show as RaShow,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import MarkdownField from '@src/components/MarkdownField';

const Show: React.FC = (props) => (
  <RaShow {...props}>
    <SimpleShowLayout>
      <ImageField source="avatar.source" />
      <TextField source="id" />
      <TextField source="name" />
      <MarkdownField source="bio" />
      <LocaleDateField source="created_at" showTime />
      <LocaleDateField source="updated_at" showTime />
    </SimpleShowLayout>
  </RaShow>
);

export default Show;
