import Icon from '@material-ui/icons/Theaters';

import List from './List';
// import Show from './Show';

export default {
  name: 'comments/series',
  icon: Icon,
  list: List,
  // show: Show,
};
