import React from 'react';
import {
  ImageField,
  Show as RaShow,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';

const Show: React.FC = (props) => (
  <RaShow {...props}>
    <SimpleShowLayout>
      <ImageField source="cover.source" />
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="description" />
      <LocaleDateField source="date" showTime />
      <LocaleDateField source="created_at" showTime />
      <LocaleDateField source="updated_at" showTime />
    </SimpleShowLayout>
  </RaShow>
);

export default Show;
