import React from 'react';
import {
  Datagrid,
  List as RaList,
  ReferenceField,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import PriceField from '@src/components/PriceField';
import { perPage } from '@src/configuration';

const List: React.FC = (props) => (
  <RaList
    {...props}
    pagination={<Pagination />}
    perPage={perPage}
    sort={{ field: 'created_at', order: 'DESC' }}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <ReferenceField reference="movie" source="movie_id" linkType="show">
        <TextField source="title" />
      </ReferenceField>
      <PriceField source="amount" />
      <LocaleDateField source="created_at" showTime />
      <LocaleDateField source="updated_at" showTime />
    </Datagrid>
  </RaList>
);

export default List;
