import React from 'react';
import { Datagrid, EditButton, List as RaList, TextField } from 'react-admin';

import Pagination from '@src/components/Pagination';
import { perPage } from '@src/configuration';

import Filter from './Filter';

const List: React.FC = (props) => (
  <RaList
    {...props}
    pagination={<Pagination />}
    filters={<Filter />}
    perPage={perPage}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </RaList>
);

export default List;
