import React from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  Create as RaCreate,
  DateTimeInput,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import defaultTranslations from '@src/assets/defaultTranslations';
import Locales from '@src/assets/locales';
import UploadImageInput from '@src/components/UploadImageInput';
import UploadS3FileInput from '@src/components/UploadS3FileInput';
import { supportedVideos } from '@src/configuration';

import { redirect } from './utils';

const Create: React.FC = (props) => {
  const location = useLocation<{ series_id?: string }>();

  return (
    <RaCreate {...props}>
      <TabbedForm
        initialValues={location.state}
        // @ts-ignore
        redirect={redirect}
      >
        <FormTab label="Title">
          <ArrayInput
            source="title_translations"
            label=""
            validate={[required()]}
            defaultValue={defaultTranslations}
          >
            <SimpleFormIterator>
              <AutocompleteInput
                source="locale"
                choices={Locales}
                validate={[required()]}
              />
              <TextInput source="content" fullWidth validate={[required()]} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Description" path="description">
          <ArrayInput
            source="description_translations"
            label=""
            validate={[required()]}
            defaultValue={defaultTranslations}
          >
            <SimpleFormIterator>
              <AutocompleteInput
                source="locale"
                choices={Locales}
                validate={[required()]}
              />
              <TextInput
                source="content"
                multiline
                fullWidth
                validate={[required()]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Details" path="details">
          {location.state?.series_id ? (
            <TextInput source="series_id" disabled />
          ) : (
            <ReferenceInput source="series_id" reference="series">
              <AutocompleteInput optionText="title" validate={[required()]} />
            </ReferenceInput>
          )}
          <NumberInput source="number" validate={[required()]} step={1} />
          <ReferenceArrayInput source="tags" reference="tag">
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <DateTimeInput source="release_date" validate={[required()]} />
          <UploadImageInput source="poster.source" validate={[required()]} />
          <UploadImageInput source="cover.source" validate={[required()]} />
          <ArrayInput source="trailers" defaultValue={[]}>
            <SimpleFormIterator>
              <UploadS3FileInput
                accept={supportedVideos}
                source="source"
                validate={[required()]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Cast" path="cast">
          <ReferenceArrayInput source="directors" reference="person">
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceArrayInput source="writers" reference="person">
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceArrayInput source="stars" reference="person">
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceArrayInput source="cast" reference="person">
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceArrayInput source="crew" reference="person">
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </RaCreate>
  );
};

export default Create;
