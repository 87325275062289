import React from 'react';

import Player from '@src/components/Player';

type Props = {
  source?: string;
  record?: any;
  addLabel?: boolean;
};

const PlayerField: React.FC<Props> = (props) => {
  return (
    <Player
      title={props.record?.title}
      titleMedia={props.record?.description}
      fullPlayer={false}
      autoPlay={false}
      src={`https://streams.youtubehalal.com/${props.record.id}/master.m3u8`}
    />
  );
};

PlayerField.defaultProps = {
  addLabel: false,
};

export default PlayerField;
