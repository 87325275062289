import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import Control from './Control';

type Props = {
  title?: string;
  subtitle?: string;
};

const Info: React.FC<Props> = ({ title, subtitle }) => (
  <Container>
    <Title noWrap>{title}</Title>
    <Subtitle noWrap>{subtitle}</Subtitle>
  </Container>
);

export default Info;

const Container = styled(Control)`
  direction: ${({ theme }) => theme.direction};
  flex-wrap: wrap;
`;

const Title = styled(Typography)`
  font-weight: bold;
  opacity: 1;
  margin-right: 5px;
  margin-left: 5px;
  max-width: 120px;
`;

const Subtitle = styled(Typography)`
  font-weight: lighter;
  opacity: 0.5;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 120px;
`;
