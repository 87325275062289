import React from 'react';
import { ImageField, ImageInput, useInput, useNotify } from 'react-admin';
import Progress from '@material-ui/core/LinearProgress';

import { supportedImages } from '@src/configuration';
import client from '@src/data/client';

type Props = any;

const UploadImageInput: React.FC<Props> = (props) => {
  const {
    input: { name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const notify = useNotify();

  const [percent, setPercent] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const handleUploadProgress = React.useCallback((progressEvent) => {
    const newPercent = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (newPercent >= 100) {
      setPercent(100);
    } else {
      setPercent(newPercent);
    }
  }, []);
  const handleChange = React.useCallback(
    async (file: File | null) => {
      if (file instanceof File) {
        try {
          setUploading(true);
          const data = new FormData();
          data.set('data', file);

          const response = await client.post('/admin/uploads/image', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: handleUploadProgress,
          });

          onChange(response.data.url);
          setUploading(false);
          setPercent(-1);
        } catch (e) {
          onChange(null);
          setUploading(false);
          setPercent(-1);
          notify(`Image upload failed: ${e.message}`, 'error');
        }
      } else {
        onChange(null);
      }
    },
    [handleUploadProgress, onChange, notify]
  );
  const handleFormat = React.useCallback((value) => ({ src: value }), []);
  const handleParse = React.useCallback((value) => value?.src, []);

  return (
    <div>
      <ImageInput
        {...props}
        format={handleFormat}
        parse={handleParse}
        name={name}
        label={props.label}
        onChange={handleChange}
        error={!!(touched && error)}
        helperText={touched && error}
        required={isRequired}
        accept={supportedImages}
        multiple={false}
      >
        <ImageField source="src" title={name} />
      </ImageInput>
      {uploading && (
        <Progress
          variant={percent !== -1 ? 'determinate' : undefined}
          value={percent}
        />
      )}
    </div>
  );
};

export default UploadImageInput;
