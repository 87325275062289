import React from 'react';
import {
  AutocompleteInput,
  Create as RaCreate,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import Currencies from '@src/assets/currencies';
import DurationInput from '@src/components/DurationInput';

import { redirect } from './utils';

const Create: React.FC = (props) => {
  const location = useLocation<{ movie_id?: string }>();

  return (
    <RaCreate {...props}>
      <SimpleForm redirect={redirect} initialValues={location.state}>
        <ReferenceInput
          source="movie_id"
          reference="movie"
          validate={[required()]}
          disabled={!!location.state?.movie_id}
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <NumberInput source="final_price" validate={[required()]} />
        <NumberInput source="show_price" validate={[required()]} />
        <AutocompleteInput
          source="currency"
          choices={Currencies}
          validate={[required()]}
          default="USD"
        />
        <NumberInput source="tax" default={0} validate={[required()]} />
        <DurationInput source="duration" />
      </SimpleForm>
    </RaCreate>
  );
};

export default Create;
