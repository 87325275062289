import React from 'react';
import { EditButton, TopToolbar } from 'react-admin';

import AddPriceAction from './AddPriceAction';
import StatusAction from './StatusAction';

type Props = {
  basePath?: string;
  data?: any;
};

const ShowActions: React.FC<Props> = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <StatusAction record={data} />
    <AddPriceAction record={data} />
  </TopToolbar>
);

export default ShowActions;
