import React from 'react';
import {
  ChipField,
  ReferenceArrayField,
  ReferenceField,
  Show as RaShow,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';

const Show: React.FC = (props) => (
  <RaShow {...props}>
    <TabbedShowLayout>
      <Tab label="resources.user.tabs.details">
        <TextField source="id" />
        <TextField source="suggest_type" />
        <ReferenceField reference="series" source="series_id">
          <TextField source="title" />
        </ReferenceField>
        <ReferenceArrayField source="movie_suggests" reference="movie">
          <SingleFieldList linkType="show">
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField source="series_suggests" reference="series">
          <SingleFieldList linkType="show">
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
        <LocaleDateField source="created_at" showTime />
        <LocaleDateField source="updated_at" showTime />
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
