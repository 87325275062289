import React, { FC, useState } from 'react';
import { DashboardMenuItem, MenuItemLink, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Theme, useMediaQuery } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import SuggestsIcon from '@material-ui/icons/EmojiObjects';
import InfoIcon from '@material-ui/icons/Info';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import ReportIcon from '@material-ui/icons/Report';
import SettingsIcon from '@material-ui/icons/Settings';

import {
  menuCommentsKey,
  menuMetaKey,
  menuReportsKey,
  menuSuggestsKey,
  menuWallsKey,
} from '@src/configuration';
import Advertise from '@src/resources/Advertise';
// import Calendar from '@src/resources/Calendar';
// import CommentEpisode from '@src/resources/CommentEpisode';
import CommentMovie from '@src/resources/CommentMovie';
// import CommentSeason from '@src/resources/CommentSeason';
// import CommentSeries from '@src/resources/CommentSeries';
import Country from '@src/resources/Country';
import Discount from '@src/resources/Discount';
import Genre from '@src/resources/Genre';
// import Live from '@src/resources/Live';
import Movie from '@src/resources/Movie';
import Occupation from '@src/resources/Occupation';
import Page from '@src/resources/Page';
import Person from '@src/resources/Person';
import Rating from '@src/resources/Rating';
// import ReportCommentEpisode from '@src/resources/ReportCommentEpisode';
import ReportCommentMovie from '@src/resources/ReportCommentMovie';
// import ReportCommentSeason from '@src/resources/ReportCommentSeason';
// import ReportCommentSeries from '@src/resources/ReportCommentSeries';
// import Series from '@src/resources/Series';
// import Subscribe from '@src/resources/Subscribe';
import SuggestMovie from '@src/resources/SuggestMovie';
// import SuggestSeries from '@src/resources/SuggestSeries';
import Tag from '@src/resources/Tag';
import Transaction from '@src/resources/Transaction';
import User from '@src/resources/User';
import WallMovie from '@src/resources/WallMovie';
// import WallSeries from '@src/resources/WallSeries';
import { AppState } from '@src/store/types';

import SubMenu from './SubMenu';

type MenuName =
  | typeof menuCommentsKey
  | typeof menuSuggestsKey
  | typeof menuMetaKey
  | typeof menuWallsKey
  | typeof menuReportsKey;

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const initialState = {
  menuMeta: localStorage.getItem(menuMetaKey) === 'true',
  menuComments: localStorage.getItem(menuCommentsKey) === 'true',
  menuSuggests: localStorage.getItem(menuSuggestsKey) === 'true',
  menuReports: localStorage.getItem(menuReportsKey) === 'true',
  menuWalls: localStorage.getItem(menuWallsKey) === 'true',
};

const menusKeys = {
  menuMeta: menuMetaKey,
  menuComments: menuCommentsKey,
  menuSuggests: menuSuggestsKey,
  menuReports: menuReportsKey,
  menuWalls: menuWallsKey,
};

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState(initialState);
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme.name); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState((state) => {
      localStorage.setItem(menusKeys[menu], JSON.stringify(!state[menu]));
      return { ...state, [menu]: !state[menu] };
    });
  };

  return (
    <div>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <MenuItemLink
        to="/user"
        primaryText={translate(`resources.user.name`, {
          smart_count: 2,
        })}
        leftIcon={<User.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to="/movie"
        primaryText={translate(`resources.movie.name`, {
          smart_count: 2,
        })}
        leftIcon={<Movie.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      {/*<MenuItemLink*/}
      {/*  to="/series"*/}
      {/*  primaryText={translate(`resources.series.name`, {*/}
      {/*    smart_count: 2,*/}
      {/*  })}*/}
      {/*  leftIcon={<Series.icon />}*/}
      {/*  onClick={onMenuClick}*/}
      {/*  sidebarIsOpen={open}*/}
      {/*  dense={dense}*/}
      {/*/>*/}
      <SubMenu
        handleToggle={() => handleToggle(menuSuggestsKey)}
        isOpen={state.menuSuggests}
        sidebarIsOpen={open}
        name="ra.suggests"
        icon={<SuggestsIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/${SuggestMovie.name}`}
          primaryText={translate(`resources.movie.name`, {
            smart_count: 2,
          })}
          leftIcon={<SuggestMovie.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        {/*<MenuItemLink*/}
        {/*  to={`/${SuggestSeries.name}`}*/}
        {/*  primaryText={translate(`resources.series.name`, {*/}
        {/*    smart_count: 2,*/}
        {/*  })}*/}
        {/*  leftIcon={<SuggestSeries.icon />}*/}
        {/*  onClick={onMenuClick}*/}
        {/*  sidebarIsOpen={open}*/}
        {/*  dense={dense}*/}
        {/*/>*/}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle(menuWallsKey)}
        isOpen={state.menuWalls}
        sidebarIsOpen={open}
        name="ra.walls"
        icon={<LocalHospitalIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/${WallMovie.name}`}
          primaryText={translate(`resources.movie.name`, {
            smart_count: 2,
          })}
          leftIcon={<WallMovie.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        {/*<MenuItemLink*/}
        {/*  to={`/${WallSeries.name}`}*/}
        {/*  primaryText={translate(`resources.series.name`, {*/}
        {/*    smart_count: 2,*/}
        {/*  })}*/}
        {/*  leftIcon={<WallSeries.icon />}*/}
        {/*  onClick={onMenuClick}*/}
        {/*  sidebarIsOpen={open}*/}
        {/*  dense={dense}*/}
        {/*/>*/}
      </SubMenu>

      {/*<MenuItemLink*/}
      {/*  to="/live"*/}
      {/*  primaryText={translate(`resources.live.name`, {*/}
      {/*    smart_count: 2,*/}
      {/*  })}*/}
      {/*  leftIcon={<Live.icon />}*/}
      {/*  onClick={onMenuClick}*/}
      {/*  sidebarIsOpen={open}*/}
      {/*  dense={dense}*/}
      {/*/>*/}
      {/*<MenuItemLink*/}
      {/*  to="/calendar"*/}
      {/*  primaryText={translate(`resources.calendar.name`, {*/}
      {/*    smart_count: 2,*/}
      {/*  })}*/}
      {/*  leftIcon={<Calendar.icon />}*/}
      {/*  onClick={onMenuClick}*/}
      {/*  sidebarIsOpen={open}*/}
      {/*  dense={dense}*/}
      {/*/>*/}
      {/*<MenuItemLink*/}
      {/*  to="/package"*/}
      {/*  primaryText={translate(`resources.package.name`, {*/}
      {/*    smart_count: 2,*/}
      {/*  })}*/}
      {/*  leftIcon={<Subscribe.icon />}*/}
      {/*  onClick={onMenuClick}*/}
      {/*  sidebarIsOpen={open}*/}
      {/*  dense={dense}*/}
      {/*/>*/}
      <MenuItemLink
        to="/discount"
        primaryText={translate(`resources.discount.name`, {
          smart_count: 2,
        })}
        leftIcon={<Discount.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/${Transaction.name}`}
        primaryText={translate(`resources.transaction.name`, {
          smart_count: 2,
        })}
        leftIcon={<Transaction.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to="/advertise"
        primaryText={translate(`resources.advertise.name`, {
          smart_count: 2,
        })}
        leftIcon={<Advertise.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to="/page"
        primaryText={translate(`resources.page.name`, {
          smart_count: 2,
        })}
        leftIcon={<Page.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle(menuCommentsKey)}
        isOpen={state.menuComments}
        sidebarIsOpen={open}
        name="ra.comments"
        icon={<CommentIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/${CommentMovie.name}`}
          primaryText={translate(`resources.movie.name`, {
            smart_count: 2,
          })}
          leftIcon={<CommentMovie.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        {/*<MenuItemLink*/}
        {/*  to={`/${CommentSeries.name}`}*/}
        {/*  primaryText={translate(`resources.series.name`, {*/}
        {/*    smart_count: 2,*/}
        {/*  })}*/}
        {/*  leftIcon={<CommentSeries.icon />}*/}
        {/*  onClick={onMenuClick}*/}
        {/*  sidebarIsOpen={open}*/}
        {/*  dense={dense}*/}
        {/*/>*/}
        {/*<MenuItemLink*/}
        {/*  to={`/${CommentSeason.name}`}*/}
        {/*  primaryText={translate(`resources.season.name`, {*/}
        {/*    smart_count: 2,*/}
        {/*  })}*/}
        {/*  leftIcon={<CommentSeason.icon />}*/}
        {/*  onClick={onMenuClick}*/}
        {/*  sidebarIsOpen={open}*/}
        {/*  dense={dense}*/}
        {/*/>*/}
        {/*<MenuItemLink*/}
        {/*  to={`/${CommentEpisode.name}`}*/}
        {/*  primaryText={translate(`resources.episode.name`, {*/}
        {/*    smart_count: 2,*/}
        {/*  })}*/}
        {/*  leftIcon={<CommentEpisode.icon />}*/}
        {/*  onClick={onMenuClick}*/}
        {/*  sidebarIsOpen={open}*/}
        {/*  dense={dense}*/}
        {/*/>*/}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle(menuReportsKey)}
        isOpen={state.menuReports}
        sidebarIsOpen={open}
        name="ra.reports"
        icon={<ReportIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/${ReportCommentMovie.name}`}
          primaryText={translate(`resources.movie.name`, {
            smart_count: 2,
          })}
          leftIcon={<ReportCommentMovie.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        {/*<MenuItemLink*/}
        {/*  to={`/${ReportCommentSeries.name}`}*/}
        {/*  primaryText={translate(`resources.series.name`, {*/}
        {/*    smart_count: 2,*/}
        {/*  })}*/}
        {/*  leftIcon={<ReportCommentSeries.icon />}*/}
        {/*  onClick={onMenuClick}*/}
        {/*  sidebarIsOpen={open}*/}
        {/*  dense={dense}*/}
        {/*/>*/}
        {/*<MenuItemLink*/}
        {/*  to={`/${ReportCommentSeason.name}`}*/}
        {/*  primaryText={translate(`resources.season.name`, {*/}
        {/*    smart_count: 2,*/}
        {/*  })}*/}
        {/*  leftIcon={<ReportCommentSeason.icon />}*/}
        {/*  onClick={onMenuClick}*/}
        {/*  sidebarIsOpen={open}*/}
        {/*  dense={dense}*/}
        {/*/>*/}
        {/*<MenuItemLink*/}
        {/*  to={`/${ReportCommentEpisode.name}`}*/}
        {/*  primaryText={translate(`resources.episode.name`, {*/}
        {/*    smart_count: 2,*/}
        {/*  })}*/}
        {/*  leftIcon={<ReportCommentEpisode.icon />}*/}
        {/*  onClick={onMenuClick}*/}
        {/*  sidebarIsOpen={open}*/}
        {/*  dense={dense}*/}
        {/*/>*/}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle(menuMetaKey)}
        isOpen={state.menuMeta}
        sidebarIsOpen={open}
        name="ra.meta"
        icon={<InfoIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/genre"
          primaryText={translate(`resources.genre.name`, {
            smart_count: 2,
          })}
          leftIcon={<Genre.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/rating"
          primaryText={translate(`resources.rating.name`, {
            smart_count: 2,
          })}
          leftIcon={<Rating.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/country"
          primaryText={translate(`resources.country.name`, {
            smart_count: 2,
          })}
          leftIcon={<Country.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/occupation"
          primaryText={translate(`resources.occupation.name`, {
            smart_count: 2,
          })}
          leftIcon={<Occupation.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/person"
          primaryText={translate(`resources.person.name`, {
            smart_count: 2,
          })}
          leftIcon={<Person.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/tag"
          primaryText={translate(`resources.tag.name`, {
            smart_count: 2,
          })}
          leftIcon={<Tag.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>

      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText={translate('ra.configuration')}
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
