import React from 'react';
import {
  BooleanInput,
  Create as RaCreate,
  DateTimeInput,
  email,
  PasswordInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { Gender } from './choices';

const Create: React.FC = (props) => (
  <RaCreate {...props}>
    <SimpleForm redirect="show">
      <TextInput type="phone" source="phone" validate={[required()]} />
      <TextInput type="email" source="email" validate={[email()]} />
      <TextInput source="name" validate={[required()]} />
      <PasswordInput source="password" validate={[required()]} />
      <SelectInput source="gender" choices={Gender} validate={[required()]} />
      <BooleanInput source="active" defaultValue={true} />
      <BooleanInput source="email_confirmed" defaultValue={false} />
      <BooleanInput source="phone_confirmed" defaultValue={false} />
      <DateTimeInput source="birth_date" validate={[required()]} />
    </SimpleForm>
  </RaCreate>
);

export default Create;
