import React from 'react';
import {
  ChipField,
  Datagrid,
  ReferenceArrayField,
  ReferenceManyField,
  Show as RaShow,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import PriceField from '@src/components/PriceField';
import { perPage } from '@src/configuration';

import ShowActions from './ShowActions';

const Show: React.FC = (props) => (
  <RaShow {...props} actions={<ShowActions />}>
    <TabbedShowLayout>
      <Tab label="resources.user.tabs.details">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="duration" />
        <ReferenceArrayField source="movies" reference="movie">
          <SingleFieldList linkType="show">
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField source="serieses" reference="series">
          <SingleFieldList linkType="show">
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
        {/*<ReferenceArrayField source="seasons" reference="season">*/}
        {/*  <SingleFieldList linkType="show">*/}
        {/*    <ChipField source="title" />*/}
        {/*  </SingleFieldList>*/}
        {/*</ReferenceArrayField>*/}
        <LocaleDateField source="created_at" showTime />
        <LocaleDateField source="updated_at" showTime />
      </Tab>
      <Tab label="ra.tabs.prices" path="prices">
        <ReferenceManyField
          reference="prices/package"
          target="media_ids"
          addLabel={false}
          pagination={<Pagination />}
          sort={{ field: 'id', order: 'DESC' }}
          perPage={perPage}
        >
          <Datagrid rowClick="edit">
            <PriceField source="final_price" />
            <PriceField source="show_price" />
            <PriceField source="tax" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
