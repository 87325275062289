import Icon from '@material-ui/icons/Theaters';

import Create from './Create';
import Edit from './Edit';
import List from './List';
import Show from './Show';

export default {
  name: 'series',
  icon: Icon,
  list: List,
  show: Show,
  create: Create,
  edit: Edit,
};
