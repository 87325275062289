import React from 'react';
import { NumberInput } from 'react-admin';

type Props = any;

const daySeconds = 24 * 60 * 60;

const DurationInput: React.FC<Props> = (props) => {
  return (
    <NumberInput
      {...props}
      helperText="Days"
      format={(value: number) => value / daySeconds}
      parse={(value: number) => value * daySeconds}
    />
  );
};

export default DurationInput;
