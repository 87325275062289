import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg
      width={44}
      height={44}
      viewBox="0 0 24 24"
      fill="none"
      aria-label="Logo"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="logo 2">
        <g id="Group 129">
          <g id="Group">
            <path
              id="Vector"
              d="M13.4425 21.1802C15.7155 20.9772 17.9573 20.368 19.8254 19.2948C22.4097 17.8445 23.9043 15.495 23.9977 13.3195C24.0288 12.2462 23.7486 11.202 23.0947 10.3608C22.5031 9.57763 21.0186 9.20055 20.4582 8.38836C19.8977 7.57618 20.6661 6.47394 20.4793 5.51672C19.9188 2.61607 15.4664 2.268 11.948 3.1672C7.83806 4.24044 3.88378 5.98083 1.57971 9.22955C-0.662089 12.3913 -0.444137 15.9881 1.76652 18.2216C3.94605 20.4551 8.77214 21.6153 13.4425 21.1802Z"
              fill="#00b140"
            />
          </g>
          <g id="Group 8">
            <g id="Group_2">
              <path
                id="Vector_2"
                d="M5.36845 19.137V14.9436C5.36845 14.7927 5.33828 14.7022 5.30811 14.5514L3.10583 7.34119H5.1271C5.6098 9.24178 6.15282 11.4441 6.33383 12.7413H6.364C6.60535 11.3536 7.14837 9.21161 7.57073 7.34119H9.47133L7.23888 14.7022C7.20871 14.7927 7.20871 14.9134 7.20871 15.1547V19.137H5.36845Z"
                fill="white"
              />
            </g>
            <g id="Group_3">
              <path
                id="Vector_3"
                d="M10.8731 9.03061H8.97253V7.34119H14.614V9.03061H12.7134V19.137H10.9033V9.03061H10.8731Z"
                fill="white"
              />
            </g>
            <g id="Group_4">
              <path
                id="Vector_4"
                d="M14.494 7.34119H16.3041V11.9871H18.265V7.34119H20.0751V19.137H18.265V13.6765H16.3041V19.137H14.494V7.34119V7.34119Z"
                fill="white"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default React.memo(Logo);
