import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  Create as RaCreate,
  DateTimeInput,
  FormTab,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';

import defaultTranslations from '@src/assets/defaultTranslations';
import Locales from '@src/assets/locales';
import UploadImageInput from '@src/components/UploadImageInput';
import UploadS3FileInput from '@src/components/UploadS3FileInput';
import { supportedVideos } from '@src/configuration';

const Create: React.FC = (props) => (
  <RaCreate {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Title">
        <ArrayInput
          source="title_translations"
          label=""
          validate={[required()]}
          defaultValue={defaultTranslations}
        >
          <SimpleFormIterator>
            <AutocompleteInput
              source="locale"
              choices={Locales}
              validate={[required()]}
            />
            <TextInput source="content" fullWidth validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Description" path="description">
        <ArrayInput
          source="description_translations"
          label=""
          validate={[required()]}
          defaultValue={defaultTranslations}
        >
          <SimpleFormIterator>
            <AutocompleteInput
              source="locale"
              choices={Locales}
              validate={[required()]}
            />
            <TextInput
              source="content"
              multiline
              fullWidth
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Details" path="details">
        <DateTimeInput source="date" validate={[required()]} />
        <UploadImageInput source="cover.source" />
        <ArrayInput source="trailers" defaultValue={[]}>
          <SimpleFormIterator>
            <UploadS3FileInput
              accept={supportedVideos}
              source="source"
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </RaCreate>
);

export default Create;
