import React from 'react';
import {
  BooleanField,
  Datagrid,
  List as RaList,
  ReferenceField,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import { perPage } from '@src/configuration';

const List: React.FC = (props) => (
  <RaList
    {...props}
    pagination={<Pagination />}
    perPage={perPage}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <Datagrid>
      <TextField source="body" />
      <ReferenceField reference="user" source="user_id">
        <TextField source="phone" />
      </ReferenceField>
      <ReferenceField reference="movie" source="movie_id">
        <TextField source="title" />
      </ReferenceField>
      <BooleanField source="active" />
      <LocaleDateField source="created_at" showTime />
    </Datagrid>
  </RaList>
);

export default List;
