import React from 'react';
import { Button, useRefresh } from 'react-admin';
import Publish from '@material-ui/icons/Publish';

import authProvider from '@src/authentication';
import client from '@src/data/client';

type Props = {
  record?: any;
};

const StatusAction: React.FC<Props> = ({ record }) => {
  const refresh = useRefresh();
  const handleClick = React.useCallback(async () => {
    if (record) {
      try {
        await client.put(`/admin/series/${record.id}/generate`);
      } catch (e) {
        await authProvider.checkError(e);
      }
      refresh();
    }
  }, [record, refresh]);

  return (
    <Button
      label="ra.publish"
      onClick={handleClick}
      disabled={record?.status === 1}
    >
      <Publish />
    </Button>
  );
};

export default React.memo(StatusAction);
