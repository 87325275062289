import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

type Props = {
  source: string;
  addLabel?: boolean;
  record?: any;
};

const ResourceReferenceField: React.FC<Props> = (props) => {
  switch (props.record?.type) {
    case 'movie':
      return (
        <ReferenceField
          {...props}
          reference="movie"
          source="resource_id"
          resource="movie"
          basePath="movie"
          link="show"
        >
          <TextField source="title" />
        </ReferenceField>
      );
    case 'series':
      return (
        <ReferenceField
          {...props}
          reference="series"
          source="resource_id"
          resource="series"
          basePath="series"
          link="show"
        >
          <TextField source="title" />
        </ReferenceField>
      );
    case 'package':
      return (
        <ReferenceField
          {...props}
          reference="package"
          source="resource_id"
          resource="package"
          basePath="package"
          link="show"
        >
          <TextField source="title" />
        </ReferenceField>
      );
    default:
      return null;
  }
};

ResourceReferenceField.defaultProps = {
  addLabel: true,
};

export default ResourceReferenceField;
