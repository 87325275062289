import React from 'react';
import { IconButton } from '@material-ui/core';
import {
  VolumeDown,
  VolumeMute,
  VolumeOff,
  VolumeUp,
} from '@material-ui/icons';
import styled from 'styled-components';

import Control from './Control';
import { useContext } from './hooks';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  bottom: 70px;
  left: -45px;
  position: absolute;
  transform: rotate(-90deg);
`;

const InputBox = styled.div`
  background: #222222;
  padding: 0 18px 10px 18px;
  margin-left: 10px;
  border-radius: 5px;
`;

const Connector = styled.div`
  width: 10px;
`;

const Input = styled.input.attrs({
  type: 'range',
  min: 0,
  max: 100,
  title: '',
})`
  outline: 0;
  border: none;
  appearance: none;
  height: 5px;
  width: 70px;
  background: #999
    ${({ value, theme }) => `linear-gradient(
      93deg,
      ${theme.palette.primary.main} ${value}%,
      rgba(210, 210, 210, 1) ${value}%
    )`};

  /* stylelint-disable property-no-vendor-prefix */
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
  }
  /* stylelint-enable property-no-vendor-prefix */
`;

const Volume: React.FC = () => {
  const {
    showVolume,
    setShowVolume,
    volume,
    setVolume,
    muted,
    setMuted,
  } = useContext();
  const handleShow = React.useCallback(() => setShowVolume(true), [
    setShowVolume,
  ]);
  const handleHide = React.useCallback(() => setShowVolume(false), [
    setShowVolume,
  ]);
  const handleMute = React.useCallback(() => setMuted(true), [setMuted]);
  const handleUnmute = React.useCallback(() => setMuted(false), [setMuted]);
  const handleZeroVolume = React.useCallback(() => setVolume(0), [setVolume]);
  const handleVolumeChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setVolume(parseFloat(e.target.value)),
    [setVolume]
  );

  if (muted) {
    return (
      <Control>
        <IconButton size="small" onClick={handleUnmute}>
          <VolumeOff />
        </IconButton>
      </Control>
    );
  }

  return (
    <Control onMouseLeave={handleHide}>
      {showVolume && (
        <Container>
          <Connector />
          <InputBox>
            <Input value={volume} onChange={handleVolumeChange} />
          </InputBox>
        </Container>
      )}

      {volume >= 60 && (
        <IconButton size="small" onMouseEnter={handleShow} onClick={handleMute}>
          <VolumeUp />
        </IconButton>
      )}

      {volume < 60 && volume >= 20 && (
        <IconButton size="small" onMouseEnter={handleShow} onClick={handleMute}>
          <VolumeDown />
        </IconButton>
      )}

      {volume < 20 && volume > 0 && (
        <IconButton size="small" onMouseEnter={handleShow} onClick={handleMute}>
          <VolumeMute />
        </IconButton>
      )}

      {volume <= 0 && (
        <IconButton
          size="small"
          onMouseEnter={handleShow}
          onClick={handleZeroVolume}
        >
          <VolumeOff />
        </IconButton>
      )}
    </Control>
  );
};

export default React.memo(Volume);
