import merge from 'lodash/merge';

import baseTheme from './base';
const main = '#00b140';

const Theme = (rtl = true) => {
  return merge(baseTheme(rtl), {
    type: 'light',
    palette: {
      primary: {
        main,
      },
      secondary: {
        light: '#58e46f',
        main: 'rgba(0, 177, 64, 0.8)',
        dark: '#00800d',
        contrastText: '#fff',
      },
      background: {
        default: '#fcfcfe',
      },
    },

    shape: {
      borderRadius: 10,
    },
    overrides: {
      RaMenuItemLink: {
        root: {
          borderLeft: '3px solid #fff',
        },
        active: {
          borderLeft: `3px solid ${main}`,
        },
      },
      MuiPaper: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '1px solid #e0e0e3',
          backgroundClip: 'padding-box',
        },
      },
      MuiButton: {
        contained: {
          backgroundColor: '#fff',
          color: main,
          boxShadow: 'none',
        },
      },
      MuiAppBar: {
        colorSecondary: {
          color: '#808080',
          backgroundColor: '#fff',
        },
      },
      MuiLinearProgress: {
        colorPrimary: {
          backgroundColor: '#f5f5f5',
        },
        barColorPrimary: {
          backgroundColor: '#d7d7d7',
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          '&$disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
  });
};

export default Theme;
