import React from 'react';
import Hls from 'hls.js';

import { useContext } from '../hooks';
import HTMLPlayer from '../HTMLPlayer';

import type { SimplePlayerProps } from './types';

const HlsVideo: React.FC<SimplePlayerProps> = ({
  autoPlay,
  onEnded,
  onError,
  onTimeUpdate,
  startPosition,
  src,
}) => {
  const video = React.useRef<HTMLVideoElement>(null);

  const {
    reset,
    volume,
    muted,
    setPlaying,
    setEnd,
    playing,
    controlBackEnd,
    setControlBackEnd,
    progress,
    error,
    setError,
    setShowInfo,
    waitingBuffer,
    setWaitingBuffer,
    setActualBuffer,
    setProgress,
    setDuration,
    setVideoReady,
    quality,
    qualities,
    setQualities,
    setQuality,
    setSubtitle,
    setSubtitles,
    setAudio,
    setAudios,
    audio,
    subtitle,
  } = useContext();
  const timerBuffer = React.useRef<number>();
  const hls = React.useRef<Hls>();

  React.useEffect(() => {
    if (video.current) {
      video.current.muted = muted;
    }
  }, [muted]);

  React.useEffect(() => {
    if (video.current) {
      video.current.volume = volume / 100;
    }
  }, [volume]);

  const handleEnded = React.useCallback(() => {
    if (video.current) {
      if (+startPosition === +video.current.duration && !controlBackEnd) {
        setControlBackEnd(true);
        video.current.currentTime = video.current.duration - 30;
        if (autoPlay) {
          setPlaying(true);
          video.current?.play();
        } else {
          setPlaying(false);
        }
      } else {
        setEnd(true);
        setPlaying(false);

        onEnded?.();
      }
    }
  }, [
    autoPlay,
    controlBackEnd,
    onEnded,
    setControlBackEnd,
    setEnd,
    setPlaying,
    startPosition,
  ]);

  const handleCanPlay = React.useCallback(() => {
    setVideoReady(true);
    setDuration(video.current?.duration ?? 0);
  }, [setDuration, setVideoReady]);

  const handleTimeUpdate = React.useCallback<
    React.ReactEventHandler<HTMLVideoElement>
  >(
    // @ts-ignore
    (e: VideoEvent) => {
      setShowInfo(false);
      setEnd(false);
      if (playing) {
        setPlaying(true);
      }

      if (waitingBuffer) {
        setWaitingBuffer(false);
      }

      if (timerBuffer.current) {
        clearTimeout(timerBuffer.current);
      }

      // @ts-ignore
      timerBuffer.current = setTimeout(() => setWaitingBuffer(true), 1000);

      onTimeUpdate?.(e);

      let choseBuffer = 0;
      let lenghtBuffer = e.target.buffered.length;
      let start = 0;
      let end = 0;
      let atualTime = e.target.currentTime;

      for (let i = 1; i <= lenghtBuffer; i++) {
        let startCheck = e.target.buffered.start(i - 1);
        let endCheck = e.target.buffered.end(i - 1);

        if (endCheck > atualTime && atualTime > startCheck) {
          choseBuffer = i;

          if (endCheck > end) {
            end = endCheck;
          }

          if (startCheck < start) {
            start = startCheck;
          }
        }
      }

      setActualBuffer({
        index: choseBuffer,
        start: start,
        end: end,
      });

      setProgress(e.target.currentTime);
    },
    [
      onTimeUpdate,
      playing,
      setActualBuffer,
      setEnd,
      setPlaying,
      setProgress,
      setShowInfo,
      setWaitingBuffer,
      waitingBuffer,
    ]
  );

  const handleError = React.useCallback<
    React.ReactEventHandler<HTMLVideoElement>
  >(
    // @ts-ignore
    (e: VideoEvent) => {
      onError?.(e);
      setError('An error occurred while trying to play this video -_-');
    },
    [onError, setError]
  );

  React.useEffect(() => {
    if (video.current && Math.abs(video.current.currentTime - progress) > 1) {
      video.current.currentTime = progress;
    }
  }, [progress]);

  React.useEffect(() => {
    if (playing) {
      video.current?.play().catch(() => setPlaying(false));
    } else {
      video.current?.pause();
    }
  }, [playing, setPlaying]);

  React.useEffect(() => {
    if (hls.current) {
      if (quality !== undefined && qualities.length > quality) {
        if (!video.current) {
          return;
        }
        video.current.pause();
        hls.current.currentLevel = quality;
      } else {
        setQuality(hls.current.currentLevel);
      }
    }
  }, [qualities.length, quality, setQuality]);

  React.useEffect(() => {
    if (hls.current && subtitle !== undefined) {
      hls.current.subtitleTrack = subtitle;
    }
  }, [subtitle]);

  React.useEffect(() => {
    if (hls.current && audio !== undefined) {
      try {
        hls.current.audioTrack = audio;
      } catch (e) {
        console.log(e);
      }
    }
  }, [audio]);

  React.useEffect(() => {
    if (src && video.current) {
      if (Hls.isSupported()) {
        hls.current = new Hls({
          // debug: true,
          enableWorker: true,
          // liveBackBufferLength: 900,
          // renderTextTracksNatively: false,
          lowLatencyMode: false,
        });

        if (!hls.current) return;

        hls.current.loadSource(src);
        hls.current.attachMedia(video.current);

        // hls.current.on('hlsCuesParsed', (cues, test) =>
        //   console.log(cues, test)
        // );
        hls.current.on(Hls.Events.LEVEL_SWITCHED, async () => {
          if (!video.current) {
            return;
          }
          await video.current.play();
        });
        hls.current.on(Hls.Events.MANIFEST_LOADED, () => {
          if (hls.current) {
            setQualities(hls.current.levels.map((level) => `${level.height}p`));

            setSubtitles(
              hls.current.subtitleTracks.map((subtitle) => subtitle.name)
            );
            setSubtitle(hls.current.subtitleTrack);

            setAudios(hls.current.audioTracks.map((audio) => audio.name));
            setAudio(hls.current.audioTrack);

            setPlaying(false);
          }
        });
      } else if (video.current.canPlayType('application/vnd.apple.mpegurl')) {
        video.current.src = src;
        video.current.oncanplay = () => {
          setPlaying(false);
        };
      }

      reset();
      setProgress(startPosition);
    }

    return () => {
      hls.current?.detachMedia();
      hls.current?.destroy();
    };
  }, [
    autoPlay,
    reset,
    setAudio,
    setAudios,
    setPlaying,
    setProgress,
    setQualities,
    setQuality,
    setSubtitle,
    setSubtitles,
    src,
    startPosition,
  ]);

  return (
    <HTMLPlayer
      muted
      className={`${!error ? 'opacity-100' : 'opacity-0'}`}
      id="player-video"
      ref={video}
      controls={false}
      autoPlay={autoPlay}
      onCanPlay={handleCanPlay}
      onTimeUpdate={handleTimeUpdate}
      onError={handleError}
      onEnded={handleEnded}
    />
  );
};

export default React.memo(HlsVideo);
