import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  Create as RaCreate,
  FormTab,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';
import MarkdownInput from 'ra-input-markdown';

import defaultTranslations from '@src/assets/defaultTranslations';
import Locales from '@src/assets/locales';

const Create: React.FC = (props) => (
  <RaCreate {...props}>
    <TabbedForm redirect="show">
      <FormTab label="title">
        <ArrayInput
          source="title_translations"
          label=""
          validate={[required()]}
          defaultValue={defaultTranslations}
        >
          <SimpleFormIterator>
            <AutocompleteInput
              source="locale"
              choices={Locales}
              validate={[required()]}
            />
            <TextInput source="content" fullWidth validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="description" path="description">
        <ArrayInput
          source="description_translations"
          label=""
          validate={[required()]}
          defaultValue={defaultTranslations}
        >
          <SimpleFormIterator>
            <AutocompleteInput
              source="locale"
              choices={Locales}
              validate={[required()]}
            />
            <MarkdownInput source="content" validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Details" path="details">
        <TextInput source="url" label="URL" fullWidth validate={[required()]} />
      </FormTab>
    </TabbedForm>
  </RaCreate>
);

export default Create;
