import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  Create as RaCreate,
  FormTab,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';
import MarkdownInput from 'ra-input-markdown';

import defaultTranslations from '@src/assets/defaultTranslations';
import Locales from '@src/assets/locales';
import UploadImageInput from '@src/components/UploadImageInput';

const Create: React.FC = (props) => (
  <RaCreate {...props}>
    <TabbedForm redirect="show">
      <FormTab label="name">
        <ArrayInput
          source="name_translations"
          label=""
          validate={[required()]}
          defaultValue={defaultTranslations}
        >
          <SimpleFormIterator>
            <AutocompleteInput
              source="locale"
              choices={Locales}
              validate={[required()]}
            />
            <TextInput
              source="content"
              multiline
              fullWidth
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="bio" path="bio">
        <UploadImageInput source="avatar.source" />
        <ArrayInput
          source="bio_translations"
          label=""
          validate={[required()]}
          defaultValue={defaultTranslations}
        >
          <SimpleFormIterator>
            <AutocompleteInput
              source="locale"
              choices={Locales}
              validate={[required()]}
            />
            <MarkdownInput
              source="content"
              multiline
              fullWidth
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="gallery" path="gallery">
        <ArrayInput source="gallery" label="">
          <SimpleFormIterator>
            <UploadImageInput source="source" validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </RaCreate>
);

export default Create;
