import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import styled from 'styled-components';

import BackButton from './BackButton';
import Backward10Control from './Backward10Control';
import Episodes from './Episodes';
import Forward10Control from './Forward10Control';
import FullScreenControl from './FullScreenControl';
import { useContext } from './hooks';
import Info from './Info';
import Locales from './Locales';
import NextEpisode from './NextEpisode';
import PlayControl from './PlayControl';
import Progress from './Progress';
import Qualities from './Qualities';
import type { Episode } from './types';
import Volume from './Volume';

type Props = {
  titleMedia: string;
  extraInfoMedia?: string;

  onBackPress?: () => void;

  onNextClick?: () => void;
  onEpisodeClick?: (id: string, playing: boolean) => void;

  nexEpisode?: {
    title: string;
    description?: string;
  };
  episodes: Episode[];
};

const Container = styled.div`
  position: absolute;
  direction: ltr;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.2s ease-out;

  padding: 10px;
  color: #fff;
  font-size: 1.5em;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.7) 20%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.7) 80%,
    rgba(0, 0, 0, 1) 100%
  );
`;

const Items = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Start = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
`;

const End = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
`;

const Controls: React.FC<Props> = ({
  onBackPress,
  titleMedia,
  extraInfoMedia,
  onNextClick,
  nexEpisode,
  onEpisodeClick,
  episodes,
}) => {
  const { showControls, videoReady, error, started } = useContext();

  return (
    <Container
      className={`${
        showControls && videoReady && !started && !error
          ? 'opacity-100 scale-0'
          : 'opacity-0 scale-1'
      }`}
    >
      <BackButton onClick={onBackPress} />

      <Progress />

      {videoReady && (
        <Items>
          <Start>
            <PlayControl />
            <Backward10Control />
            <Forward10Control />

            <Volume />

            <Hidden xsDown implementation="js">
              <Info title={titleMedia} subtitle={extraInfoMedia} />
            </Hidden>
          </Start>

          <End>
            <NextEpisode onClick={onNextClick} {...nexEpisode} />
            <Episodes onChange={onEpisodeClick} episodes={episodes} />
            <Qualities />
            <Locales />
            <FullScreenControl />
          </End>
        </Items>
      )}
    </Container>
  );
};

export default React.memo(Controls);
