import React from 'react';
import { Theme } from '@material-ui/core';

import { useRTL } from '@src/i18n';
import { dark, light } from '@src/themes';

import useThemeName from './useThemeName';

const useTheme = () => {
  const rtl = useRTL();
  const themeName = useThemeName();
  return (React.useMemo(() => (themeName === 'dark' ? dark(rtl) : light(rtl)), [
    rtl,
    themeName,
  ]) as unknown) as Theme;
};

export default useTheme;
