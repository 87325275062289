import React from 'react';
import { Typography } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import styled, { css } from 'styled-components';

import { useContext } from './hooks';

type Props = {
  title: string;
  subtitle?: string;
};

const InfoOverlay: React.FC<Props> = ({ title, subtitle }) => {
  const {
    showInfo,
    videoReady,
    playing,
    started,
    setStarted,
    setShowInfo,
    setPlaying,
  } = useContext();

  React.useEffect(() => {
    if (videoReady) {
      setStarted(true);
    }
  }, [setStarted, videoReady]);

  React.useEffect(() => {
    if (playing) {
      setStarted(false);
    }
  }, [playing, setStarted]);

  if (started) {
    return (
      <Container
        show
        onClick={() => {
          if (videoReady) {
            setStarted(false);
            setShowInfo(false);
            setPlaying(true);
          }
        }}
      >
        <CenterContent>
          <Typography
            variant="h5"
            color="textPrimary"
            component="h1"
            noWrap
            gutterBottom
          >
            Click to start
          </Typography>
          <PlayCircleOutlineIcon />
        </CenterContent>
      </Container>
    );
  }

  return (
    <Container show={showInfo && videoReady && !playing}>
      {(title || subtitle) && (
        <Content>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            component="span"
            gutterBottom
          >
            You are watching
          </Typography>
          <Typography
            variant="h5"
            color="textPrimary"
            component="h1"
            noWrap
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            variant="h6"
            color="textSecondary"
            component="h2"
            noWrap
            gutterBottom
          >
            {subtitle}
          </Typography>
        </Content>
      )}
      <Bottom color="primary">Paused</Bottom>
    </Container>
  );
};

export default React.memo(InfoOverlay);

const Container = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 50px;
  transition: all 0.5s ease-out;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
  ${({ show }) =>
    show
      ? css`
          opacity: 1;
          z-index: 10;
        `
      : css`
          opacity: 0;
        `}
`;

const Content = styled.div`
  margin: auto 0;
  padding-top: 100px;
  padding-left: 100px;
`;

const CenterContent = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  svg {
    width: 100px;
    height: 100px;
  }
`;

const Bottom = styled(Typography)`
  margin-top: auto;
  margin-bottom: 20px;
  margin-left: auto;
  text-transform: uppercase;
`;
