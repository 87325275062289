import React from 'react';
import {
  Datagrid,
  EditButton,
  ImageField,
  ReferenceManyField,
  Show as RaShow,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import { perPage } from '@src/configuration';

import AddNewEpisodeButton from './AddNewEpisodeButton';

const Show: React.FC = (props) => (
  <RaShow {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <ImageField source="cover.source" />
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="description" />
        <LocaleDateField source="release_date" showTime />
        <LocaleDateField source="created_at" showTime />
        <LocaleDateField source="updated_at" showTime />
        <ImageField source="poster.source" />
      </Tab>
      <Tab label="Episodes" path="episodes">
        <ReferenceManyField
          reference="episode"
          target="seasons_ids"
          addLabel={false}
          pagination={<Pagination />}
          perPage={perPage}
        >
          <Datagrid rowClick="show">
            <TextField source="title" />
            <LocaleDateField source="created_at" showTime />
            <LocaleDateField source="updated_at" showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <AddNewEpisodeButton />
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
