import React from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  Create as RaCreate,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';

import defaultTranslations from '@src/assets/defaultTranslations';
import Locales from '@src/assets/locales';
import UploadS3FileInput from '@src/components/UploadS3FileInput';
import { supportedImages, supportedVideos } from '@src/configuration';

const Create: React.FC = (props) => (
  <RaCreate {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Title">
        <ArrayInput
          source="title_translations"
          label=""
          validate={[required()]}
          defaultValue={defaultTranslations}
        >
          <SimpleFormIterator>
            <AutocompleteInput
              source="locale"
              choices={Locales}
              validate={[required()]}
            />
            <TextInput source="content" fullWidth validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Details" path="details">
        <UploadS3FileInput
          source="source.source"
          validate={[required()]}
          accept={`${supportedVideos},${supportedImages}`}
        />
        <TextInput source="link" fullWidth validate={[required()]} />
        <NumberInput source="skip_time" fullWidth validate={[required()]} />
        <ReferenceArrayInput source="movies" reference="movie">
          <AutocompleteArrayInput optionText="title" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="episodes" reference="episode">
          <AutocompleteArrayInput optionText="title" />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </RaCreate>
);

export default Create;
