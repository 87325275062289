import Icon from '@material-ui/icons/Receipt';

import List from './List';
import Show from './Show';

export default {
  name: 'transaction',
  icon: Icon,
  list: List,
  show: Show,
};
