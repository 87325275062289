import React from 'react';
import { Datagrid, EditButton, List as RaList, TextField } from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import { perPage } from '@src/configuration';

import Filter from './Filter';
const List: React.FC = (props) => (
  <RaList
    {...props}
    pagination={<Pagination />}
    filters={<Filter />}
    perPage={perPage}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <LocaleDateField source="created_at" showTime />
      <LocaleDateField source="updated_at" showTime />
      <EditButton />
    </Datagrid>
  </RaList>
);

export default List;
