import React from 'react';
import { FileField, FileInput, useInput, useNotify } from 'react-admin';
import Progress from '@material-ui/core/LinearProgress';
import S3, { ManagedUpload } from 'aws-sdk/clients/s3';
import { get } from 'lodash';
import mime from 'mime-types';
import { v4 as uuidv4 } from 'uuid';

const s3 = new S3({
  endpoint: 'https://ams3.digitaloceanspaces.com',
  accessKeyId: 'I2R2PJYO4COYVJIDH7CS',
  secretAccessKey: 'Tlq1f608MmTXntEZT9NVyNC6tjHVKZevTIXI72UyTL8',
  region: 'ams3',
});

type Props = {
  accept: string;
  source: string;
  [x: string]: any;
};

const kilobyte = 1000;
const megabyte = kilobyte * 1000;

const UploadS3FileInput: React.FC<Props> = ({ accept, ...props }) => {
  const {
    input: { name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const notify = useNotify();
  const [percent, setPercent] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const [title, setTitle] = React.useState(
    get(props, `record.${name}`, get(props, 'record.source', ''))
      .split('/')
      .pop()
  );
  const handleUploadProgress = React.useCallback(
    (progress: ManagedUpload.Progress) => {
      const newPercent = Math.round((progress.loaded * 100) / progress.total);
      if (newPercent >= 100) {
        setPercent(-1);
      } else {
        setPercent(newPercent);
      }
    },
    []
  );

  const handleCallback = React.useCallback(
    (key: string) => (err: any) => {
      if (err) {
        onChange(null);
        setUploading(false);
        setPercent(-1);
        setTitle('');
        notify(`${name} upload failed: ${err.message}`, 'error');
      } else {
        const url = `https://files.youtubehalal.com/${key}`;
        onChange(url);
        setTitle(url.split('/').pop());
        setUploading(false);
      }
    },
    [name, notify, onChange]
  );

  const handleChange = React.useCallback(
    async (file: File | null) => {
      if (file instanceof File) {
        setUploading(true);
        let type: string | false = file.type;
        const extension = file.name.split('.').pop();
        if (!file.type) {
          type = mime.lookup(file.name);
        }

        if (!type) {
          setUploading(false);
          return;
        }

        const key = `${uuidv4()}.${extension}`;
        s3.upload(
          {
            Bucket: 'yth-files',
            Key: key,
            Body: file,
            ContentType: type,
            ACL: 'public-read',
          },
          {
            queueSize: 1,
            partSize: 25 * megabyte,
          },
          handleCallback(key)
        ).on('httpUploadProgress', handleUploadProgress);
      } else {
        onChange(null);
        setTitle('');
      }
    },
    [handleCallback, handleUploadProgress, onChange]
  );
  const handleFormat = React.useCallback((value) => {
    return { src: value };
  }, []);
  const handleParse = React.useCallback((value) => value?.src, []);

  return (
    <div>
      <FileInput
        {...props}
        format={handleFormat}
        parse={handleParse}
        name={name}
        label={props.label}
        onChange={handleChange}
        error={!!(touched && error)}
        helperText={touched && error}
        required={isRequired}
        multiple={false}
        accept={accept}
      >
        <FileField source="src" title={title} />
      </FileInput>
      {uploading && (
        <Progress
          variant={percent === -1 ? 'indeterminate' : 'determinate'}
          value={percent}
        />
      )}
    </div>
  );
};

export default UploadS3FileInput;
