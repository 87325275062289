import React, { cloneElement, FC, useMemo } from 'react';
import { ExportButton, ListActionsProps, TopToolbar } from 'react-admin';
import {
  sanitizeListRestProps,
  useListContext,
  useResourceContext,
} from 'ra-core';

const ListActions: FC<ListActionsProps> = (props) => {
  const { className, exporter, filters, ...rest } = props;
  const {
    currentSort,
    displayedFilters,
    filterValues,
    selectedIds,
    showFilter,
    total,
  } = useListContext(props);
  const resource = useResourceContext(rest);
  return useMemo(
    () => (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        {exporter !== false && (
          <ExportButton
            maxResults={1000000}
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
          />
        )}
      </TopToolbar>
    ),
    [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

export default ListActions;
