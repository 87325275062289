import React from 'react';

import Context from './context';

const playerVolumeKey = 'player:volume';
const playerQualityKey = 'player:quality';

const Provider: React.FC = ({ children }) => {
  const localVolume = 50;
  const [quality, setQuality] = React.useState<number | undefined>(undefined);
  const [qualities, setQualities] = React.useState<string[]>([]);
  const [audios, setAudios] = React.useState<string[]>([]);
  const [subtitles, setSubtitles] = React.useState<string[]>([]);
  const [audio, setAudio] = React.useState<number>();
  const [subtitle, setSubtitle] = React.useState<number>();
  const [showLocales, setShowLocales] = React.useState(false);
  const [showNext, setShowNext] = React.useState(false);
  const [showEpisodes, setShowEpisodes] = React.useState(false);
  const [showQualities, setShowQualities] = React.useState(false);
  const [showVolume, setShowVolume] = React.useState(false);
  const [volume, setVolume] = React.useState(localVolume);
  const [muted, setMuted] = React.useState(false);
  const [playing, setPlaying] = React.useState(true);
  const [end, setEnd] = React.useState(false);
  const [controlBackEnd, setControlBackEnd] = React.useState(false);
  const [videoReady, setVideoReady] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [waitingBuffer, setWaitingBuffer] = React.useState(false);
  const [showControls, setShowControls] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState(true);
  const [started, setStarted] = React.useState(true);
  const [actualBuffer, setActualBuffer] = React.useState({
    index: 0,
    start: 0,
    end: 0,
  });

  React.useEffect(() => {
    localStorage.setItem(playerVolumeKey, `${volume}`);
  }, [volume]);

  React.useEffect(() => {
    localStorage.setItem(playerQualityKey, `${quality}`);
  }, [quality]);

  const reset = React.useCallback(() => {
    setShowNext(false);
    setShowEpisodes(false);
    setShowQualities(false);
    setDuration(0);
    setVideoReady(false);
    setError(undefined);
    setActualBuffer({
      index: 0,
      start: 0,
      end: 0,
    });
  }, []);

  const value = React.useMemo(
    () => ({
      audios,
      setAudios,
      subtitles,
      setSubtitles,
      audio,
      setAudio,
      subtitle,
      setSubtitle,
      showLocales,
      setShowLocales,
      quality,
      setQuality,
      qualities,
      setQualities,
      setShowNext,
      showNext,
      showEpisodes,
      setShowEpisodes,
      showQualities,
      setShowQualities,
      showVolume,
      setShowVolume,
      volume,
      setVolume,
      muted,
      setMuted,
      playing,
      setPlaying,
      end,
      setEnd,
      reset,
      controlBackEnd,
      setControlBackEnd,
      videoReady,
      setVideoReady,
      progress,
      setProgress,
      duration,
      setDuration,
      fullScreen,
      setFullScreen,
      error,
      setError,
      waitingBuffer,
      setWaitingBuffer,
      showControls,
      setShowControls,
      showInfo,
      setShowInfo,
      actualBuffer,
      setActualBuffer,
      started,
      setStarted,
    }),
    [
      actualBuffer,
      audio,
      audios,
      controlBackEnd,
      duration,
      end,
      error,
      fullScreen,
      muted,
      playing,
      progress,
      qualities,
      quality,
      reset,
      showControls,
      showEpisodes,
      showInfo,
      showLocales,
      showNext,
      showQualities,
      showVolume,
      started,
      subtitle,
      subtitles,
      videoReady,
      volume,
      waitingBuffer,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Provider;
