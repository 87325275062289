import React from 'react';
import {
  ChipField,
  Datagrid,
  EditButton,
  ImageField,
  ReferenceArrayField,
  ReferenceManyField,
  Show as RaShow,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import PriceField from '@src/components/PriceField';
import { perPage } from '@src/configuration';

import ShowActions from './ShowActions';

const Show: React.FC = (props) => (
  <RaShow {...props} actions={<ShowActions />}>
    <TabbedShowLayout>
      <Tab label="Details">
        <ImageField source="cover.source" />
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="description" />
        <LocaleDateField source="release_date" showTime />
        <LocaleDateField source="created_at" showTime />
        <LocaleDateField source="updated_at" showTime />
        <ImageField source="poster.source" />
        <ReferenceArrayField reference="genre" source="genres">
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField reference="rating" source="ratings">
          <SingleFieldList linkType="show">
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField reference="tag" source="tags">
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField reference="person" source="directors">
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField reference="person" source="writers">
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField reference="person" source="stars">
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField reference="person" source="cast">
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField reference="person" source="crew">
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Tab>
      <Tab label="Seasons" path="seasons">
        <ReferenceManyField
          reference="season"
          target="series_ids"
          addLabel={false}
          pagination={<Pagination />}
          perPage={perPage}
        >
          <Datagrid rowClick="show">
            <TextField source="title" />
            <LocaleDateField source="created_at" showTime />
            <LocaleDateField source="updated_at" showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="ra.tabs.prices" path="prices">
        <ReferenceManyField
          reference="prices/series"
          target="media_ids"
          addLabel={false}
          pagination={<Pagination />}
          sort={{ field: 'id', order: 'DESC' }}
          perPage={perPage}
        >
          <Datagrid rowClick="edit">
            <PriceField source="final_price" />
            <PriceField source="show_price" />
            <PriceField source="tax" />
            <TextField source="duration" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
