import React from 'react';
import { Admin, cacheDataProviderProxy, Resource } from 'react-admin';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as Sentry from '@sentry/react';

import authProvider from '@src/authentication';
import Dashboard from '@src/components/Dashboard';
import Layout from '@src/components/Layout';
import RTL from '@src/components/RTL';
import dataProvider from '@src/data';
import history from '@src/history';
import i18nProvider from '@src/i18n';
import Advertise from '@src/resources/Advertise';
import Calendar from '@src/resources/Calendar';
import CommentEpisode from '@src/resources/CommentEpisode';
import CommentMovie from '@src/resources/CommentMovie';
import CommentSeason from '@src/resources/CommentSeason';
import CommentSeries from '@src/resources/CommentSeries';
import Country from '@src/resources/Country';
import Discount from '@src/resources/Discount';
import Episode from '@src/resources/Episode';
import Genre from '@src/resources/Genre';
import Live from '@src/resources/Live';
import Movie from '@src/resources/Movie';
import Occupation from '@src/resources/Occupation';
import Page from '@src/resources/Page';
import Person from '@src/resources/Person';
import PriceMovie from '@src/resources/PriceMovie';
import PricePackage from '@src/resources/PricePackage';
import PriceSeries from '@src/resources/PriceSeries';
import Rating from '@src/resources/Rating';
import ReportCommentEpisode from '@src/resources/ReportCommentEpisode';
import ReportCommentMovie from '@src/resources/ReportCommentMovie';
import ReportCommentSeason from '@src/resources/ReportCommentSeason';
import ReportCommentSeries from '@src/resources/ReportCommentSeries';
import Season from '@src/resources/Season';
import Series from '@src/resources/Series';
import Subscribe from '@src/resources/Subscribe';
import SuggestMovie from '@src/resources/SuggestMovie';
import SuggestSeries from '@src/resources/SuggestSeries';
import Tag from '@src/resources/Tag';
import Transaction from '@src/resources/Transaction';
import User from '@src/resources/User';
import WallMovie from '@src/resources/WallMovie';
import WallSeries from '@src/resources/WallSeries';
import customRoutes from '@src/routes';
import customReducers from '@src/store';

const App = () => (
  <>
    <CssBaseline />
    <RTL>
      <Admin
        history={history}
        title={process.env.REACT_APP_NAME}
        layout={Layout}
        dashboard={Dashboard}
        i18nProvider={i18nProvider}
        // @ts-ignore
        dataProvider={cacheDataProviderProxy(dataProvider)}
        authProvider={authProvider}
        customReducers={customReducers}
        customRoutes={customRoutes}
      >
        <Resource {...User} />
        <Resource {...Movie} />
        <Resource {...Series} />
        <Resource {...Season} />
        <Resource {...Episode} />
        <Resource {...Genre} />
        <Resource {...Rating} />
        <Resource {...Country} />
        <Resource {...Occupation} />
        <Resource {...Person} />
        <Resource {...Tag} />
        <Resource {...Page} />
        <Resource {...Live} />
        <Resource {...Calendar} />
        <Resource {...CommentMovie} />
        <Resource {...CommentSeries} />
        <Resource {...CommentSeason} />
        <Resource {...CommentEpisode} />
        <Resource {...Advertise} />
        <Resource {...Subscribe} />
        <Resource {...PriceMovie} />
        <Resource {...PriceSeries} />
        <Resource {...PricePackage} />
        <Resource {...Discount} />
        <Resource {...SuggestMovie} />
        <Resource {...SuggestSeries} />
        <Resource {...ReportCommentMovie} />
        <Resource {...ReportCommentSeries} />
        <Resource {...ReportCommentSeason} />
        <Resource {...ReportCommentEpisode} />
        <Resource {...Transaction} />
        <Resource {...WallSeries} />
        <Resource {...WallMovie} />
      </Admin>
    </RTL>
  </>
);

export default Sentry.withProfiler(App);
