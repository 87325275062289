import React from 'react';
import { IconButton } from '@material-ui/core';
import { SkipNext } from '@material-ui/icons';

import Control from './Control';
import { useContext } from './hooks';

type Props = {
  onClick?: () => void;
  title?: string;
  description?: string;
};

const NextEpisode: React.FC<Props> = ({ onClick, title, description }) => {
  const { setShowNext, showNext } = useContext();
  const handleMouseEnter = React.useCallback(() => setShowNext(true), [
    setShowNext,
  ]);
  const handleMouseLeave = React.useCallback(() => setShowNext(false), [
    setShowNext,
  ]);

  if (!onClick) {
    return null;
  }
  return (
    <Control onMouseLeave={handleMouseLeave}>
      {showNext && title && (
        <div className="item-component">
          <div className="content-next">
            <div className="title">Next episode</div>
            <div className="item" onClick={onClick}>
              <div className="bold">{title}</div>
              {description && <div>{description}</div>}
            </div>
          </div>
          <div className="box-connector" />
        </div>
      )}

      <IconButton
        size="small"
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
      >
        <SkipNext />
      </IconButton>
    </Control>
  );
};

export default React.memo(NextEpisode);
