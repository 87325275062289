import React from 'react';
import { IconButton } from '@material-ui/core';
import { Forward10 } from '@material-ui/icons';

import Control from './Control';
import { useContext } from './hooks';

const seconds = 10;

const Forward10Control: React.FC = () => {
  const { duration, progress, setProgress } = useContext();

  const handleClick = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (e) => {
      e.stopPropagation();

      if (progress + seconds >= duration - 2) {
        setProgress(duration - 1);
        return;
      }

      setProgress(progress + seconds);
    },
    [duration, progress, setProgress]
  );

  return (
    <Control>
      <IconButton size="small" onClick={handleClick}>
        <Forward10 />
      </IconButton>
    </Control>
  );
};

export default React.memo(Forward10Control);
