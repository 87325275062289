import React from 'react';
import styled from 'styled-components';

import { useContext } from './hooks';

const Container = styled.div`
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  display: flex;
`;

const Animator = styled.div`
  display: flex;
  margin: auto;

  div {
    &:nth-child(2) {
      animation-delay: 0.1s;
    }

    &:nth-child(3) {
      animation-delay: 0.2s;
    }

    animation: 1s linear toUpOpacity infinite;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.main};
    margin: auto 5px;
  }
`;

const Loading: React.FC = () => {
  const { videoReady, waitingBuffer, playing, error, end } = useContext();

  if ((videoReady && (!waitingBuffer || !playing)) || error || end) {
    return null;
  }

  return (
    <Container>
      <Animator>
        <div />
        <div />
        <div />
      </Animator>
    </Container>
  );
};

export default React.memo(Loading);
