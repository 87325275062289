import React from 'react';
import {
  BooleanField,
  NumberField,
  Show as RaShow,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';

const Show: React.FC = (props) => (
  <RaShow {...props}>
    <TabbedShowLayout>
      <Tab label="resources.user.tabs.details">
        <TextField source="code" />
        <TextField source="description" />
        <BooleanField source="active" />
        <NumberField source="amount" />
        <NumberField source="percent" />
        <NumberField source="maximum" />
        <NumberField source="total" />
        <LocaleDateField source="expire_at" />
        <LocaleDateField source="created_at" showTime />
        <LocaleDateField source="updated_at" showTime />
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
