import jsonExport from 'jsonexport/dist';
import { downloadCSV, Exporter } from 'react-admin';

import { gatewayMap } from './utils';

const exporter: Exporter = async (records: any[], fetchRelatedRecords) => {
  const [users, movies, series, packages] = await Promise.all([
    fetchRelatedRecords(records, 'user_id', 'user'),
    fetchRelatedRecords(
      records.filter((record) => record?.type === 'movie'),
      'resource_id',
      'movie'
    ),
    fetchRelatedRecords(
      records.filter((record) => record?.type === 'series'),
      'resource_id',
      'series'
    ),
    fetchRelatedRecords(
      records.filter((record) => record?.type === 'package'),
      'resource_id',
      'package'
    ),
  ]);
  const data = records.map((record: any) => {
    let resource = '';
    switch (record?.type) {
      case 'movie':
        resource = movies[record.resource_id].title;
        break;
      case 'series':
        resource = series[record.resource_id].title;
        break;
      case 'package':
        resource = packages[record.resource_id].title;
    }

    return {
      tracking_code: record.tracking_code,
      user: users[record.user_id]?.phone ?? record?.email ?? record.user_id,
      resource,
      action: record.action,
      gateway: gatewayMap[record.gateway]?.name ?? record.gateway,
      first_amount: `${record.first_amount} ${record.currency ?? 'USD'}`,
      final_amount: `${record.final_amount} ${record.currency ?? 'USD'}`,
      active: record.active ? 'yes' : 'no',
      created_at: new Date(record.created_at).toLocaleString('en'),
    };
  });

  return jsonExport(
    data,
    {
      headers: [
        'tracking_code',
        'user',
        'resource',
        'action',
        'gateway',
        'first_amount',
        'final_amount',
        'active',
        'created_at',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'transactions');
    }
  );
};

export default exporter;
