import React from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  Edit as RaEdit,
  FormTab,
  ReferenceArrayInput,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';

import defaultTranslations from '@src/assets/defaultTranslations';
import Locales from '@src/assets/locales';
import DurationInput from '@src/components/DurationInput';

const Edit: React.FC = (props) => (
  <RaEdit {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Title">
        <ArrayInput
          source="title_translations"
          label=""
          validate={[required()]}
          defaultValue={defaultTranslations}
        >
          <SimpleFormIterator>
            <AutocompleteInput
              source="locale"
              choices={Locales}
              validate={[required()]}
            />
            <TextInput source="content" fullWidth validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Content" path="content">
        <DurationInput source="duration" validate={[required()]} />

        <ReferenceArrayInput source="movies" reference="movie" fullWidth>
          <AutocompleteArrayInput optionText="title" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="serieses" reference="series" fullWidth>
          <AutocompleteArrayInput optionText="title" />
        </ReferenceArrayInput>
        {/*<ReferenceArrayInput source="seasons" reference="season">*/}
        {/*  <AutocompleteArrayInput optionText="title" />*/}
        {/*</ReferenceArrayInput>*/}
      </FormTab>
    </TabbedForm>
  </RaEdit>
);

export default Edit;
