import React from 'react';
import ReactMarkdown from 'react-markdown';
import { get } from 'lodash';

type Props = {
  record?: object;
  source: string;
  addLabel?: boolean;
};

const MarkdownField: React.FC<Props> = ({ source, record = {} }) => (
  <ReactMarkdown source={get(record, source)} />
);

MarkdownField.defaultProps = {
  addLabel: true,
};

export default MarkdownField;
