import React from 'react';
import noop from 'lodash/noop';

type ContextProps = {
  reset: () => void;
  showNext: boolean;
  setShowNext: (value: boolean) => void;
  showEpisodes: boolean;
  setShowEpisodes: (value: boolean) => void;
  showQualities: boolean;
  setShowQualities: (value: boolean) => void;
  qualities: string[];
  setQualities: (value: string[]) => void;
  quality?: number;
  setQuality: (value?: number) => void;
  showLocales: boolean;
  setShowLocales: (value: boolean) => void;
  audios: string[];
  setAudios: (value: string[]) => void;
  subtitles: string[];
  setSubtitles: (value: string[]) => void;
  audio?: number;
  setAudio: (value?: number) => void;
  subtitle?: number;
  setSubtitle: (value?: number) => void;
  showVolume: boolean;
  setShowVolume: (value: boolean) => void;
  volume: number;
  setVolume: (value: number) => void;
  playing: boolean;
  setPlaying: (value: boolean) => void;
  controlBackEnd: boolean;
  setControlBackEnd: (value: boolean) => void;
  end: boolean;
  setEnd: (value: boolean) => void;
  muted: boolean;
  setMuted: (value: boolean) => void;
  videoReady: boolean;
  setVideoReady: (value: boolean) => void;
  progress: number;
  setProgress: (value: number) => void;
  duration: number;
  setDuration: (value: number) => void;
  fullScreen: boolean;
  setFullScreen: (value: boolean) => void;
  error: string | undefined;
  setError: (value: string | undefined) => void;
  waitingBuffer: boolean;
  setWaitingBuffer: (value: boolean) => void;
  showControls: boolean;
  setShowControls: (value: boolean) => void;
  showInfo: boolean;
  setShowInfo: (value: boolean) => void;
  started: boolean;
  setStarted: (value: boolean) => void;
  actualBuffer: { start: number; index: number; end: number };
  setActualBuffer: (value: {
    start: number;
    index: number;
    end: number;
  }) => void;
};

const Context = React.createContext<ContextProps>({
  showNext: false,
  setShowNext: noop,
  showEpisodes: false,
  setShowEpisodes: noop,
  showQualities: false,
  setShowQualities: noop,
  qualities: [],
  setQualities: noop,
  quality: undefined,
  setQuality: noop,
  showLocales: false,
  setShowLocales: noop,
  audios: [],
  setAudios: noop,
  subtitles: [],
  setSubtitles: noop,
  audio: undefined,
  setAudio: noop,
  subtitle: undefined,
  setSubtitle: noop,
  showVolume: false,
  setShowVolume: noop,
  muted: false,
  setMuted: noop,
  playing: false,
  setPlaying: noop,
  controlBackEnd: false,
  setControlBackEnd: noop,
  end: false,
  setEnd: noop,
  volume: 50,
  setVolume: noop,
  reset: noop,
  videoReady: false,
  setVideoReady: noop,
  progress: 0,
  setProgress: noop,
  duration: 0,
  setDuration: noop,
  fullScreen: false,
  setFullScreen: noop,
  error: undefined,
  setError: noop,
  waitingBuffer: false,
  setWaitingBuffer: noop,
  showControls: false,
  setShowControls: noop,
  showInfo: true,
  setShowInfo: noop,
  actualBuffer: { start: 0, end: 0, index: 0 },
  setActualBuffer: noop,
  started: true,
  setStarted: noop,
});

export default Context;
