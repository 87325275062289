import React from 'react';
import {
  Datagrid,
  ImageField,
  ReferenceManyField,
  Show as RaShow,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import PlayerField from '@src/components/PlayerField';
import PriceField from '@src/components/PriceField';
import { perPage } from '@src/configuration';

import ShowActions from './ShowActions';

const Show: React.FC = (props) => (
  <RaShow {...props} actions={<ShowActions />}>
    <TabbedShowLayout>
      <Tab label="ra.tabs.details">
        <ImageField source="cover.source" />
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="description" />
        <LocaleDateField source="release_date" showTime />
        <LocaleDateField source="created_at" showTime />
        <LocaleDateField source="updated_at" showTime />
        <ImageField source="poster.source" />
      </Tab>
      <Tab label="ra.tabs.prices" path="prices">
        <ReferenceManyField
          reference="prices/movie"
          target="media_ids"
          addLabel={false}
          pagination={<Pagination />}
          sort={{ field: 'id', order: 'DESC' }}
          perPage={perPage}
        >
          <Datagrid rowClick="edit">
            <PriceField source="final_price" />
            <PriceField source="show_price" />
            <PriceField source="tax" />
            <TextField source="duration" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="ra.tabs.preview" path="preview">
        <PlayerField />
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
