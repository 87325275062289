import React from 'react';
import {
  Datagrid,
  List as RaList,
  ReferenceField,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import { perPage } from '@src/configuration';

const List: React.FC = (props) => (
  <RaList
    {...props}
    pagination={<Pagination />}
    perPage={perPage}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <Datagrid rowClick="show">
      <ReferenceField reference="user" source="user_id">
        <TextField source="phone" />
      </ReferenceField>
      <TextField source="body" />
      {/*<TextField source="category" />*/}
      <ReferenceField reference="comments/movie" source="comment_movie_id">
        <TextField source="body" />
      </ReferenceField>
      <LocaleDateField source="created_at" showTime />
    </Datagrid>
  </RaList>
);

export default List;
