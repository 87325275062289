import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { Check, Subtitles } from '@material-ui/icons';
import styled from 'styled-components';

import Control from './Control';
import { useContext } from './hooks';

type Props = {};

const Container = styled.div`
  bottom: 20px;
  right: -20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  min-width: 250px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  background: #222222;
  border-radius: 5px;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  span {
    margin-right: 5px;
  }

  svg {
    fill: ${({ theme }) => theme.palette.primary.main};
    font-size: 2em;
    margin-left: auto;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background: #333;
  }
`;

const Connector = styled.div`
  height: 20px;
  width: 100%;
`;

const Locales: React.FC<Props> = () => {
  const {
    showLocales,
    setShowLocales,
    subtitle,
    setSubtitle,
    subtitles,
    audios,
    audio,
    setAudio,
  } = useContext();
  const handleMouseEnter = React.useCallback(() => setShowLocales(true), [
    setShowLocales,
  ]);
  const handleMouseLeave = React.useCallback(() => setShowLocales(false), [
    setShowLocales,
  ]);

  if (subtitles.length === 0 && audios.length === 0) {
    return null;
  }
  return (
    <Control onMouseLeave={handleMouseLeave}>
      {showLocales && (
        <Container>
          <Row>
            <Content>
              <Typography variant="subtitle2">Subtitle</Typography>

              <Item
                key={-1}
                onClick={() => {
                  setShowLocales(false);
                  setSubtitle(-1);
                }}
              >
                <span>Off</span>
                {subtitle === -1 && <Check />}
              </Item>

              {subtitles.map((item, index) => (
                <Item
                  key={index}
                  onClick={() => {
                    setShowLocales(false);
                    setSubtitle(index);
                  }}
                >
                  <span>{item}</span>
                  {subtitle === index && <Check />}
                </Item>
              ))}
            </Content>
            <Content>
              <Typography variant="subtitle2">
                <b>Audio</b>
              </Typography>

              {audios.map((item, index) => (
                <Item
                  key={index}
                  onClick={() => {
                    setShowLocales(false);
                    setAudio(index);
                  }}
                >
                  <span>{item}</span>
                  {audio === index && <Check />}
                </Item>
              ))}
            </Content>
          </Row>

          <Connector />
        </Container>
      )}

      <IconButton size="small" onMouseEnter={handleMouseEnter}>
        <Subtitles />
      </IconButton>
    </Control>
  );
};

export default React.memo(Locales);
