import React from 'react';
import { IconButton } from '@material-ui/core';
import { Fullscreen, FullscreenExit } from '@material-ui/icons';

import Control from './Control';
import { useContext } from './hooks';

const FullScreenControl: React.FC = () => {
  const { fullScreen, setFullScreen, setShowInfo } = useContext();

  const handleEnterFullScreenClick = React.useCallback(() => {
    setShowInfo(true);
    setFullScreen(true);
  }, [setFullScreen, setShowInfo]);

  const handleExitFullScreenClick = React.useCallback(() => {
    setFullScreen(false);
  }, [setFullScreen]);

  return (
    <Control>
      {!fullScreen && (
        <IconButton size="small" onClick={handleEnterFullScreenClick}>
          <Fullscreen />
        </IconButton>
      )}
      {fullScreen && (
        <IconButton size="small" onClick={handleExitFullScreenClick}>
          <FullscreenExit />
        </IconButton>
      )}
    </Control>
  );
};

export default React.memo(FullScreenControl);
